import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "underscore";
import moment from "moment";
import ReactDom from 'react-dom';


import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Col, Row
} from "shards-react";
import {reactLocalStorage as Storage} from "reactjs-localstorage";

import './inAppOrder.scss';

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

//inport images
import noImg from "../../../images/img/no_img.jpg";
import history from "../../../utils/history";
import {Link, Redirect} from "react-router-dom";




@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, isLogin: teamUser.isLogin, me:teamUser.me,  updateInAppOrder:teamUser.updateInAppOrder, checkOrder:teamUser.checkOrder, freeEventApply: teamUser.freeEventApply }))
@inject(({ company }) => ({ getSalesReport: company.getSalesReport }))

@observer
class orderEnd extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable salesReport = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);
  @observable isEventFree = false;
  //page
  @observable redirectInfo = {
    is: false,
    pathname: '/welcome'
  }
  @observable orderInfo = {};


  constructor(props) {
    super(props);
    this.init()
  }


  componentDidUpdate(prevProps, prevState){
    // console.log(prevProps)
    // console.log(prevState)
    // console.log(this.props.me)
  }

  @action
  init = async (key) => {

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.teamUser.email = this.teamUser.email ? this.teamUser.email : this.teamUser.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};

      this.store.useExpiryDateDiffNow = moment(moment(this.store.useExpiryDate).format('YYYY-MM-DD')).diff(moment(), 'days')+1;
      this.permissions = this.props.me.permissions;

    }
    else {

      if(Storage.get('token')) {
        //todo 재확인 - 로그인 후 해당 페이지로 넘어 오는 경우 authCheck() 함수 미샐행 할 수 있게.
        //this.props.authCheck();
      }
      // else {
      //   window.location.replace('signIn');
      // }

    }
  };


  render() {


    return (
        <div className="main-content">
          {( this.isLogin && this.teamUser.emailId ) ?
              <div className="basic-info-box">
                <form onSubmit={e => this.onSubmit(e)}>

                  <div className="section-box basic-info-table" id="inAppOrder">
                    <div className="section-tit">
                      <h3>성공적으로 주문이 완료 되었습니다.</h3>
                      <p>{moment(this.store.useExpiryDate).format('YYYY년 MM월 DD일')} 까지 소중한 서비스 이용 기간을 신청해 주셔서 너무나 감사합니다.<br /></p>
                      <Link to={"/inAppOrderList"}><strong>주문내역 보기</strong></Link>
                    </div>
                  </div>
                </form>
              </div>
              :
              <p className='empty'><a href={'/signin'}>로그인하세요.</a></p>
          }

        </div>
    )
  }
}

export default orderEnd;
