import React from 'react'
import { observable, action } from 'mobx'
import axios from "axios/index";
import gql from "../utils/gql";
import _ from "underscore";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import GoodsList from "../containers/pages/goods/goodsList";

//https://fontawesomeicons.com/materialdesign/icons

const originAxiosInstance = axios.create({
  //baseURL: 'https://ocapi.onreview.co.kr/api/upload'
  baseURL: 'https://ocapi.onreview.co.kr'
});

export class Common {

  @observable curNavInfo = {};
  @observable category = [];
  @observable orgCategory = [];
  @observable isShowModal = false;

  // src/routes.js의 name값 참고
  @observable navItems = [
    {
      label: "대시보드",
      mIcon: 'dashboard',
      name: "dashboard",
      to: "/dashboard"
    },
    {
      label: "리뷰/답글",
      mIcon: 'playlist_add_check',
      name: "review",
      to: "/reviewList",
      submenu: [
        {label: "리뷰 리스트", to: "/reviewList",  name: "reviewList", mIcon: 'library_books'},
        {label: "리뷰 마일리지", to: "/reviewMileage",  name: "reviewMileage", mIcon: 'real_estate_agent'},
        {label: "상품별 답글 설정", to: "/replyByProductList",  name: "reviewByProductList", mIcon: 'category'},
      ]
    },
    {
      label: "리뷰 위젯",
      mIcon: 'app_registration',
      name: "widget",
      to: "/widgetList",
      submenu: [
        {label: "위젯 리스트", to: "/widgetList",  name: "widgetList", mIcon: 'art_track'},
        {label: "상품별", to: "/productWidgetList",  name: "productWidgetList", mIcon: 'category'}
      ]
    },
    {
      label: "설정",
      mIcon: 'settings',
      name: "settings",
      to: "/reviewWriteConfig",
      submenu: [
        {label: "리뷰 설정", to: "/reviewWriteConfig",  name: "reviewWriteConfig", mIcon: 'mode_edit'},
        {label: "마일리지 지급 설정", to: "/mileageConfig",  name: "mileageConfig", mIcon: 'local_parking'},
        {label: "리뷰(상품) 그룹화", to: "/reviewGroup",  name: "reviewGroup", mIcon: 'group_work'},
        {label: "리뷰 답글 설정", to: "/replyWriteConfig",  name: "replyWriteConfig", mIcon: 'question_answer'},
        {label: "상품별 답글 설정", to: "/replyByProductList",  name: "replyByProductList", mIcon: 'category'},
      ]
    },
    /*{
      label: "상품",
      mIcon: 'category',
      name: "goods",
      to: "/goodsList"
    },*/
    {
      label: "디자인 관리",
      mIcon: 'design_services',
      isShowSuperAdmin: true,
      name: "design",
      to: "/banner",
      submenu: [
        // {label: "고객 공지사항", to: "/web2app", mIcon: 'table_view'},
        {label: "배너 관리", to: "/banner",  name: "banner", mIcon: 'view_agenda'}
      ]
    },
    {
      label: "관리자 정보",
      mIcon: 'store',
      name: "store",
      to: "/teamUserInfo",
      submenu: [
        // {label: "고객 공지사항", to: "/web2app", mIcon: 'table_view'},
        {label: "관리자 정보", to: "/teamUserInfo",  name: "teamUserInfoEdit", mIcon: 'person'},
        {label: "회사 정보", to: "/companyInfo",  name: "teamUserInfoEdit", mIcon: 'store'},
      ]
    },
    {
      label: "결제",
      mIcon: 'payment',
      name: "payment",
      to: "/inAppOrder",
      submenu: [
        {label: "결제", to: "/inAppOrder",  name: "inAppOrder", mIcon: 'payment'},
        {label: "결제 내역", to: "/inAppOrderList",  name: "inAppOrderList", mIcon: 'receipt'},
      ]
    },
    {
      label: "스토어 관리",
      mIcon: 'business',
      isShowSuperAdmin: true,
      name: "storeList",
      to: "/storeList",
      submenu: [
        {label: "스토어 리스트", to: "/storeList",  name: "StoreList", mIcon: 'store'}
      ]
    },
  ];

  @observable navItemsIndexByName = _.indexBy(this.navItems, 'name');

  @action getCurNav = ( ) => {
    return this.curNavInfo;
  };

  @action setIsShowPopup = (isStatus) => {
    return this.isShowModal = isStatus;
  };

  @action getIsShowPopup = () => {
    return this.isShowModal;
  };




  @action setPageInfo = (route) => {

    let submenuByTo = {};
    let res = {};
    if(this.navItemsIndexByName[route.info.mainMenu].submenu && this.navItemsIndexByName[route.info.mainMenu].submenu[0] && this.navItemsIndexByName[route.info.mainMenu].submenu[0].submenu) {
      for(let index in this.navItemsIndexByName[route.info.mainMenu].submenu) {
        let row = this.navItemsIndexByName[route.info.mainMenu].submenu[index];
        let middleMenu = row.label;
        for(let index in row.submenu) {
          let cRow = row.submenu[index]
          cRow.middleMenu = middleMenu;
          submenuByTo[cRow.to] = cRow;
        }
      }
    }
    else {
      submenuByTo = _.indexBy(this.navItemsIndexByName[route.info.mainMenu].submenu, 'to');
    }
    res.route = route;
    res.mainMenu = route.info.mainMenu;
    res.mainMenu = this.navItemsIndexByName[route.info.mainMenu];
    res.submenu = submenuByTo[route.path];
    this.curNavInfo = res;
  };


  @action getConBoard = ( boardkey ) => {

    let result = Storage.getObject('board_' + boardkey);
    result.boardKeys = Storage.getObject('boardKeys');
    if(result) {
      return result;
    }
    else {
      console.log(result)
    }

  };



  @action getSetting = ( cb ) => {

    let v = Storage.get('v');

    //test
    v = '0.0.0'
    let params = {
      type: 'query',
      operation: 'getSetting',
      variables: { v  : v ? v : '0.0.0' },
      getData: 'data'
    };

    const gpqParams = gql(params);
    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];
        if(result) {
          if(result.data.v) {
            Storage.set('v', result.data.v);
          }
          if(result.data.ConBoard) {
            let boardKeys = [];
            result = _.indexBy(result.data.ConBoard, 'key')
            for(let key in result) {
              Storage.setObject('board_' + key , result[key]);
              boardKeys.push(key)
            }
            Storage.setObject('boardKeys' , boardKeys);
          }
        }
        else {
          throw res.data.errors;
        }

      })
      .catch(function (error) {
        return error;
      });
  };

  @action getStoreTotalConfig = (data, cb) => {

    let property = ['store_id']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getStoreTotalConfig',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];
        if(typeof cb === 'function') {
          cb(result.data);
        }
        else {
          return result.data;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

  @action getDoc = (operation, param, cb ) => {
    let params = {
      type: 'query',
      operation: 'getDoc',
      variables: {operation: operation, param:param},
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];

        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action uploadImages = (params, cb) => {

    return originAxiosInstance.post('/api/upload', params)
        .then(function (res) {
          let result = res.data;
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action cafe24UploadImages = (data, cb) => {

    let property = ['store_id', 'imagesUrls']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'cafe24UploadImages',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result.data);
          }
          else {
            return result.data;
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action base64UploadImages = (data, cb) => {

    let property = ['store_id', 'base64Imgs']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'base64UploadImages',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result.data);
          }
          else {
            return result.data;
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action updateDoc = (operation, param, cb ) => {


    let params = {
      type: 'mutation',
      operation: 'updateDoc',
      variables: {operation: operation, param:param},
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];
        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action getDashBoardReport = (data, cb) => {

    let property = ['store_id']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getDashBoardReport',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result.data);
          }
          else {
            return result.data;
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };


  @action delDoc = (operation, param, cb ) => {

    let params = {
      type: 'mutation',
      operation: operation,
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getCategory = (param, cb) => {

    let category = this.category;
    let orgCategory = this.orgCategory;
    if(category.length > 0) {
      return category;
    }
    else {
      let params = {
        type: 'query',
        operation: 'getCategory',
        variables: param ? param : {},
        getData: 'id cls depth label order'
      };

      const gpqParams = gql(params);

      return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            orgCategory = res.data.data[params.operation];

            let row = {};
            let proc_1 = _.groupBy(res.data.data[params.operation], function(item){ return item.depth; });

            _.each(proc_1, function(data, index) {

              let proc_2;

              if(index === "1") {
                proc_2 = data;
              }
              else {
                proc_2 = _.groupBy(data, function(item){ return item.key });
              }
              row['depth_' + index] = proc_2;
              category = row;
            });
            if(typeof cb === 'function') {
              cb(category);
            }
            else {

              return proc_1;
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    }

  };



  @action updateCategory = (param, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateCategory',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action updateCategoryOrder = (param, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateCategoryOrder',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let result = res.data.data[params.operation];


        if(typeof cb === 'function') {
          cb(result);
        }
        else {
          return result;
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };


  @action getUploadUrl = (mediaType, cb) => {

    let variables = {mediaType:mediaType};
    let params = {
      type: 'query',
      operation: 'getUploadUrl',
      variables: variables,
      getData: 'data'
    };

    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            if(!res.data.data[params.operation] || !res.data.data[params.operation].data || !res.data.data[params.operation].data.result || !res.data.data[params.operation].data.result.uploadURL) {
              console.log(res.data.data[params.operation]);
            }
            else {
              if(mediaType == 'video') {
                cb(res.data.data[params.operation].data.result)
              }
              else {
                cb(res.data.data[params.operation].data.result.uploadURL)
              }

            }

          }
          else {
            console.log(res.data.data[params.operation])
            //console.log(res.data.data[params.operation])
            //return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        return error;
      });

  }

  @action uploadImg = (uploadUrl, file, cb) => {
    const formData = new FormData();
    formData.append("file", file.files[0]);
    axios.post(uploadUrl, formData )
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.result)
          }
          else {
            return res.data.result;
          }
        }
      })
      .catch(function (error) {
        return error;
      });
  }

  @action removeMedia = (param, cb) => {
    let params = {
      type: 'query',
      operation: 'removeMedia',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  }


  @action sendMail = (param,cb) => {


    let params = {
      type: 'mutation',
      operation: 'sendMail',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };

  @action testCafe24Api = (param,cb) => {


    let params = {
      type: 'mutation',
      operation: 'testCafe24Api',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };


  @action getCollectionCount = (param, cb) => {
    let params = {
      type: 'query',
      operation: 'getCollectionCount',
      variables: param,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          let result = res.data.data[params.operation];
          if(typeof cb === 'function') {
            cb(result);
          }
          else {
            return result;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  }

}
