const gql = (params) => {
    const operation = params.operation;
    const queryType = params.type;
    const getData = params.getData;
    const variables = params.variables;
    let queryStr = queryType + ' ' + operation + ' { ' + operation;

    if(Object.keys(variables).length > 0) {
        queryStr += '( ';
        for (let key in variables) {
            // if(typeof variables[key] === 'boolean' || typeof variables[key] === 'number') {
            //     queryStr += key + ': ' + variables[key] + ' ';
            // }
            if(typeof variables[key] === 'string') {
                queryStr += key + ': "' + variables[key] + '" ';
            }
            else if (typeof variables[key] === 'object') {

                let jsonStr = JSON.stringify(variables[key]);
                jsonStr = jsonStr.replace(/\"([^(\")"]+)\":/g,"$1:");

                queryStr += key + ': ' + jsonStr + ' ';
            }
            else {
                queryStr += key + ': ' + variables[key] + ' ';
            }

        }
        queryStr += ') '

    }
    queryStr += ' {' + getData + '}}';

    const gpqParams = {
        operationName: operation,
        query: queryStr,
        variables: {}
    };

    return gpqParams;
};
export default gql;
