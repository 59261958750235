import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import './style.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";

@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, isLogin: teamUser.isLogin, me:teamUser.me }))
@observer
class Welcome extends Component {

  @observable teamUser = {
    userId: '',
    password: ''
  };
  @observable redirectInfo = {
    is: false,
    pathname: '/'
  }

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    console.log(this.props.isLogin)
    if(this.props.isLogin) {
      this.redirectInfo.is = true;
    }
    else {
      let auth = await this.props.authCheck();
      console.log(auth)
      console.log(this.props.isLogin, this.teamUser.me);
    }

  };

  render() {
    return (
        <>
        {this.redirectInfo.is ? (
              <Redirect to={{pathname: this.redirectInfo.pathname}} />
          ) : (
              <>
                <div className="welcome-content">
                  <div className="welcome-content-bg">
                    <div className="welcome-content-wrap">
                      <div className="welcome-txt">감사합니다😊<br/>이제 리뷰 서비스를 활용하여<br/> 고객에게 실패 없는 쇼핑을 제공할 수 있도록<br/> 멋지게 세팅해 주세요!</div>
                      <div className="btn-box"><a className="save-button" href='/signin'>시작하기</a></div>
                      {/*<div className="welcome-bottom">*/}
                      {/*  <div className="welcome-link-guide">온리플 가이드 및 사용법 보기</div>*/}
                      {/*  <div className="welcome-info">이런 기능을 활용해 보세요.*/}
                      {/*    <div className="welcome-info-list">*/}
                      {/*      <div className="welcome-info-item">- 기능1</div>*/}
                      {/*      <div className="welcome-info-item">- 기능2</div>*/}
                      {/*      <div className="welcome-info-item">- 기능3 설명</div>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </>
              )}
        </>
    )
  }
}

export default Welcome;
