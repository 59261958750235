import { Common } from './Common';
import { TeamUser } from './TeamUser';
import { Company } from './Company';
import { Product } from './Product';
import { Review } from './Review';
import { Widget } from './Widget';
import { AOA } from './AOA';

class Store {
  constructor() {
    this.common = new Common(this);
    this.teamUser = new TeamUser(this);
    this.company = new Company(this);
    this.product = new Product(this);
    this.review = new Review(this);
    this.widget = new Widget(this);
    this.AOA = new AOA(this);
  }
}
export default Store;
