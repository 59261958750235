import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup, FormSelect, FormInput, Button, FormCheckbox, FormTextarea, InputGroup, InputGroupText, InputGroupAddon
} from "shards-react";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


@inject(({ common }) => ({ updateDoc: common.updateDoc, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))

// reviewFilteringSchema
// company_id: {type: Schema.Types.ObjectId, ref: 'company'},
// filterWords: {type: Object},

@observer
class MileageConfig extends Component {

  //basic
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;

  //page
  @observable noticeText = "";
  @observable mileageSettings = {
    member: { text: 0, image:0, movie:0, instagram:0 },
    noMember: { text: 0, image:0, movie:0,instagram:0 },
    isNoMemberDiff: false,
    isAuto: false
  }

  @observable mileageBonusSettings = {
    //하나만 지급
    isOnlyOnce: true,
    //회원최초
    joinFirst: {
      isUse: false,
      mileage_1: 1000,
    },
    //선착순
    fsb: {
      isUse: false,
      mileage_1: 1000,
      range_1 : 10,
    },
    //이미지 수
    perImg: {
      isUse: false,
      mileage_1: 1000,
      range_1 : 5,
    },
    //글 수
    perText: {
      isUse: false,
      mileage_1: 1000,
      range_1 : 300,
    },
    //조기 작성
    early: {
      isUse: false,
      mileage_1: 1000,
      range_1 : 5,
    },
    //영상 선착순
    fsbMovie: {
      isUse: false,
      mileage_1: 1000,
      range_1 : 10,
    },
    //영상 길이
    perMovie: {
      isUse: false,
      mileage_1: 1000,
      range_1 : 3,
    },
    //sns 최초 작성
    snsJoinFirst: {
      isUse: false,
      mileage_1: 1000,
    },
    //sns 선착순
    snsFsb: {
      isUse: false,
      mileage_1: 1000,
      range_1 : 10,
    }
  }


  constructor(props) {
    super(props);
    this.init()

    this.onChangeInputs = this.onChangeInputs.bind(this);
    this.onChangeChecked = this.onChangeChecked.bind(this);
  }

  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    let _this = this;
    if(this.props.isLogin) {
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      // console.log(this.teamUser);
      // this.comp1any = this.props.me.company ? this.props.me.company : {};
      this.permissions = this.props.me.permissions;

      //page
      this.props.getDoc('getMileageConfig', {store_id: this.teamUser.selStore_id}, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          let results;
          if(res && res.data) {
            results = res.data;
            console.log(results)
            if(results.store_id) {
              _this.noticeText = results.noticeText ? results.noticeText : '';
              _this.mileageSettings = results.mileageSettings ? results.mileageSettings : _this.mileageSettings;
              _this.mileageBonusSettings = results.mileageBonusSettings ? results.mileageBonusSettings : _this.mileageBonusSettings;
            }
          }
        }
        _this.mileageSettings.store_id = _this.teamUser.selStore_id;
        _this.mileageBonusSettings.store_id = _this.teamUser.selStore_id;
      });

    }
  };

  onChangeInputs = async (event, targetObj) => {
    const { name, value } = event.target;
    targetObj[name] = value;
  };

  onChangeChecked = async (event, targetObj, keyArray) => {

    if(keyArray[0] == 'isRate') {
      if(!targetObj[keyArray[0]]) {
        alert('지급 적립금을 백분율(%) 단위로 입력해 주세요.');
      }
      else {
        alert('지급 적립금을 적립금 단위로 입력해 주세요.');
      }
      targetObj.member = { text: 0, image:0, movie:0, instagram:0 };
    }

    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
    }
  };

  updateNoticeText = async (event) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      noticeText: this.noticeText,
    }

    this.props.updateDoc('updateMileageConfig', data, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
      //todo 페이지 새로 고침
    });
  };

  updateMileageConfig = async (event) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      mileageSettings: this.mileageSettings,
    }

    this.props.updateDoc('updateMileageConfig', data, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
      //todo 페이지 새로 고침
    });
  };

  updateMileageBonusSettings = async (event) => {

    let data = {
      store_id: this.teamUser.selStore_id,
      mileageBonusSettings: this.mileageBonusSettings,
    }

    this.props.updateDoc('updateMileageConfig', data, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
      //todo 페이지 새로 고침
    });
  };



  render() {
    return (
        <div className="main-content">
          <PageTitle />
          <div className="benefits-box">
            <div className="section-box">
              <div className="section-tit">
                <h3>적립금 안내 문구(상세 리뷰리스트 상단 안내 표시)</h3>
              </div>
              <FormGroup>
                <FormInput id="noticeText" value={this.noticeText} type="text" name="noticeText" className="" onChange={event => this.onChangeInputs(event, this)} placeholder="" />
              </FormGroup>
              <div className="btn-save">
                <Button className="save-button" onClick={event => this.updateNoticeText(event)}>저장</Button>
              </div>
            </div>

            <div className="section-box" id="basicMileageSettings">
              <div className="mileage-payments-table">
                <div className="section-tit">
                  <h3>기본 적립금 지급 설정</h3>
                  {/*<div className="table-sub-tit">*/}
                  {/*  <span>각 항목에 대해서는 <span className="text-red">누적</span>하여 아래 <span className="text-red">합산된 적립금가 지급</span>됩니다. 적립금 지급방식</span>*/}
                  {/*  <a href="#" className="detail-more">자세히 알아보기</a>*/}
                  {/*</div>*/}
                </div>
                <FormGroup>
                  <FormCheckbox toggle small checked={this.mileageSettings.isAuto || false} onChange={event=> this.onChangeChecked(event, this.mileageSettings, ['isAuto'])} >
                    자동 적립(리뷰 작성 즉시 자동으로 적립합니다.)
                  </FormCheckbox>
                </FormGroup>
                <FormGroup>
                  <FormCheckbox toggle small checked={this.mileageSettings.isRate || false} onChange={event=> this.onChangeChecked(event, this.mileageSettings, ['isRate'])} >
                    결제 금액에 비례 백분율(%)로 지급
                  </FormCheckbox>
                </FormGroup>
                { this.mileageSettings.isRate ? (
                    <><p> - ex.상품 가격이 1000원이고 10%프로 적립금을 지급설정 -> 100 적립금 지급</p>
                      <p> - 10자리에서 반올림하여 지급합니다.</p></>) : null
                }
                <br />
                <table className="ss-table">
                  <tbody>
                  <tr>
                    <td></td>
                    <td colSpan="3">일반리뷰</td>
                    {/*<td>SNS 리뷰</td>*/}
                  </tr>
                  <tr>
                    <td>항목</td>
                    <td>텍스트</td>
                    <td>포토</td>
                    {/*<td>동영상</td>*/}
                    {/*<td>인스타그램</td>*/}
                  </tr>
                  <tr>
                    <td>일반회원</td>
                    <td>
                      <InputGroup className="mb-2">
                        <FormInput value={this.mileageSettings.member.text} type="number" name="text" className="table-input-number text-right" onChange={event => this.onChangeInputs(event, this.mileageSettings.member)} placeholder="" />
                        { this.mileageSettings.isRate ? ( <InputGroupAddon type="append"><InputGroupText>%</InputGroupText></InputGroupAddon> ) : <InputGroupAddon type="append"><InputGroupText>적립금</InputGroupText></InputGroupAddon> }
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <FormInput value={this.mileageSettings.member.image} type="number" name="image" className="table-input-number text-right" onChange={event => this.onChangeInputs(event, this.mileageSettings.member)} placeholder="" />
                        { this.mileageSettings.isRate ? ( <InputGroupAddon type="append"><InputGroupText>%</InputGroupText></InputGroupAddon> ) : <InputGroupAddon type="append"><InputGroupText>적립금</InputGroupText></InputGroupAddon> }
                      </InputGroup>
                    </td>
                    {/*<td><FormGroup><FormInput value={this.mileageSettings.member.movie} type="number" name="movie" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageSettings.member)} placeholder="" /></FormGroup></td>*/}
                    {/*<td><FormGroup><FormInput value={this.mileageSettings.member.instagram} type="number" name="instagram" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageSettings.member)} placeholder="" /></FormGroup></td>*/}
                  </tr>
                  {/*<tr>*/}
                  {/*  <td>비회원</td>*/}
                  {/*  <td>*/}
                  {/*    <InputGroup>*/}
                  {/*      <FormInput value={this.mileageSettings.noMember.text} type="number" name="text" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageSettings.noMember)} placeholder="" />*/}
                  {/*      <InputGroupAddon type="append">*/}
                  {/*        <InputGroupText>%</InputGroupText>*/}
                  {/*      </InputGroupAddon>*/}
                  {/*    </InputGroup>*/}
                  {/*  </td>*/}
                  {/*  <td>*/}
                  {/*    <InputGroup>*/}
                  {/*      <FormInput value={this.mileageSettings.noMember.image} type="number" name="image" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageSettings.noMember)} placeholder="" />*/}
                  {/*      <InputGroupAddon type="append">*/}
                  {/*        <InputGroupText>%</InputGroupText>*/}
                  {/*      </InputGroupAddon>*/}
                  {/*    </InputGroup>*/}
                  {/*    </td>*/}
                  {/*  /!*<td><FormGroup><FormInput value={this.mileageSettings.noMember.movie} type="number" name="movie" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageSettings.noMember)} placeholder="" /></FormGroup></td>*!/*/}
                  {/*  /!*<td><FormGroup><FormInput value={this.mileageSettings.noMember.instagram} type="number" name="instagram" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageSettings.noMember)} placeholder="" /></FormGroup></td>*!/*/}
                  {/*</tr>*/}
                  </tbody>
                </table>
                <div className="table-guide"><p>*고정 적립금가 아닌 상품 결제 금액에 비례 백분율(%)로 지급 가능(10단위에 반올림)</p></div>
                {/*<div className="table-switch">*/}
                {/*  <FormCheckbox toggle small checked={this.mileageSettings.isNoMemberDiff} onChange={event=> this.onChangeChecked(event, this.mileageSettings, ['isNoMemberDiff'])} >*/}
                {/*    비회원 종류에 따라 적립금 다르게 설정*/}
                {/*  </FormCheckbox>*/}
                {/*</div>*/}
              </div>
              <div className="btn-save">
                <Button className="save-button" onClick={event => this.updateMileageConfig(event)}>저장</Button>
              </div>
            </div>


            <div className="section-box" id="bonusMileageSettings">
              <div className="add-mileage-table">
                <div className="section-tit">
                  <h3>추가 적립금</h3>
                </div>
                <FormCheckbox toggle small checked={this.mileageBonusSettings.isBonusAuto || false} onChange={event=> this.onChangeChecked(event, this.mileageBonusSettings, ['isBonusAuto'])} >
                  추가 적립금 자동 적립(리뷰 작성 즉시 자동으로 적립합니다.)
                </FormCheckbox>
                <div className="basic-review mt-5">
                  <table className="s-table">
                    <tbody>
                    <tr className="active">
                      <th>
                        <FormCheckbox toggle small checked={this.mileageBonusSettings.isOnlyOnce || false}
                                      onChange={event => this.onChangeChecked(event, this.mileageBonusSettings, ['isOnlyOnce'])}>
                          추가적립금는 한 번만 지급
                        </FormCheckbox>
                        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon"/></span>
                      </th>
                      <td>
                        <div className="option-value">리뷰에서 추가적립금 종류는 최대 적립금 1번만 받도록 설정</div>
                      </td>
                    </tr>
                    <tr className="active">
                      <th>
                        <FormCheckbox toggle small checked={this.mileageBonusSettings.perText.isUse || false}
                                      onChange={event => this.onChangeChecked(event, this.mileageBonusSettings.perText, ['isUse'])}>
                          장문 텍스트
                        </FormCheckbox>
                        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon"/></span>
                      </th>
                      <td>
                        <div className="option-value">
                          <FormInput value={this.mileageBonusSettings.perText.range_1 || 0} type="number" name="range_1"
                                     className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.perText)}
                                     placeholder=""/>
                          자 이상일 경우 추가 적립금
                          <FormInput value={this.mileageBonusSettings.perText.mileage_1 || 0} type="number"
                                     name="mileage_1" className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.perText)}
                                     placeholder=""/>
                          지급
                        </div>
                      </td>
                    </tr>
                    <tr className="active">
                      <th>
                        <FormCheckbox toggle small checked={this.mileageBonusSettings.perImg.isUse || false}
                                      onChange={event => this.onChangeChecked(event, this.mileageBonusSettings.perImg, ['isUse'])}>
                          많은 이미지
                        </FormCheckbox>
                        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon"/></span>
                      </th>
                      <td>
                        <div className="option-value">
                          <FormInput value={this.mileageBonusSettings.perImg.range_1 || 0} type="number" name="range_1"
                                     className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.perImg)}
                                     placeholder=""/>
                          장 이상일 경우 추가 적립금
                          <FormInput value={this.mileageBonusSettings.perImg.mileage_1 || 0} type="number"
                                     name="mileage_1" className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.perImg)}
                                     placeholder=""/>
                          지급
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <FormCheckbox toggle small checked={this.mileageBonusSettings.fsb.isUse || false}
                                      onChange={event => this.onChangeChecked(event, this.mileageBonusSettings.fsb, ['isUse'])}>
                          선착순
                        </FormCheckbox>
                        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon"/></span>
                      </th>
                      <td>
                        <div className="option-value">제품당 리뷰 선착순
                          <FormInput value={this.mileageBonusSettings.fsb.range_1 || 0} type="number" name="range_1"
                                     className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.fsb)}
                                     placeholder=""/>
                          등 까지 추가 적립금
                          <FormInput value={this.mileageBonusSettings.fsb.mileage_1 || 0} type="number" name="mileage_1"
                                     className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.fsb)}
                                     placeholder=""/>
                          지급
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <FormCheckbox toggle small checked={this.mileageBonusSettings.joinFirst.isUse || false}
                                      onChange={event => this.onChangeChecked(event, this.mileageBonusSettings.joinFirst, ['isUse'])}>
                          회원 최초
                        </FormCheckbox>
                      </th>
                      <td>
                        <div className="option-value">회원가입 후 첫 번째로 작성한 포토리뷰에 추가 적립금
                          <FormInput value={this.mileageBonusSettings.joinFirst.mileage_1 || 0} type="number"
                                     name="mileage_1" className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.joinFirst)}
                                     placeholder=""/>
                          지급
                        </div>
                      </td>
                    </tr>


                    {/*<tr className="active">
                      <th>
                        <FormCheckbox toggle small checked={this.mileageBonusSettings.early.isUse || false}
                                      onChange={event => this.onChangeChecked(event, this.mileageBonusSettings.early, ['isUse'])}>
                          조기 작성 인센티브
                        </FormCheckbox>
                        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon"/></span>
                      </th>
                      <td>
                        <div className="option-value">
                          배송완료 후
                          <FormInput value={this.mileageBonusSettings.early.range_1 || 0} type="number" name="range_1"
                                     className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.early)}
                                     placeholder=""/>
                          안에 리뷰 작성 시 추가 적립금
                          <FormInput value={this.mileageBonusSettings.early.mileage_1 || 0} type="number"
                                     name="mileage_1" className="table-input-number text-center"
                                     onChange={event => this.onChangeInputs(event, this.mileageBonusSettings.early)}
                                     placeholder=""/>
                          지급
                        </div>
                      </td>
                    </tr>*/}


                    {/*<tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.mileageBonusSettings.isFsbMovie || false} onChange={event=> this.onChangeChecked(event, this.mileageBonusSettings, ['isFsbMovie'])} >
                        동영상 리뷰 선착순
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>
                    <td>
                      <div className="option-value">
                        동영상리뷰 선착순
                        <FormInput value={this.mileageBonusSettings.fsbMovieNum_1 || ''} type="number" name="fsbMovieNum_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        등 까지 추가 적립금
                        <FormInput value={this.mileageBonusSettings.fsbMovie_1 || ''} type="number" name="fsbMovie_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>
                  <tr className="active">
                    <th>
                      <FormCheckbox toggle small checked={this.mileageBonusSettings.isPerMovie || false} onChange={event=> this.onChangeChecked(event, this.mileageBonusSettings, ['isPerMovie'])} >
                        동영상 길이
                      </FormCheckbox>
                      <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>
                    </th>트
                    <td>
                      <div className="option-value">
                        <FormInput value={this.mileageBonusSettings.perMovieNum_1 || ''} type="number" name="perMovieNum_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        초 이상일 경우 추가 적립금
                        <FormInput value={this.mileageBonusSettings.perMovie_1 || ''} type="number" name="perMovie_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        지급
                      </div>
                      <div className="option-value add-option">
                        <FormInput value={this.mileageBonusSettings.perMovieNum_2 || ''} type="number" name="perMovieNum_2" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        초 이상일 경우 추가 적립금
                        <FormInput value={this.mileageBonusSettings.perMovie_2 || ''} type="number" name="perMovie_2" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        지급
                      </div>
                      <div className="option-value add-option">
                        <FormInput value={this.mileageBonusSettings.perMovieNum_3 || ''} type="number" name="perMovieNum_3" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        초 이상일 경우 추가 적립금
                        <FormInput value={this.mileageBonusSettings.perMovie_3 || ''} type="number" name="perMovie_3" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />
                        지급
                      </div>
                    </td>
                  </tr>*/}
                    </tbody>
                  </table>
                </div>
                {/*<div className="sns-review">*/}
                {/*  <div className="table-tit">*/}
                {/*    <h4>SNS리뷰</h4>*/}
                {/*  </div>*/}
                {/*  <table>*/}
                {/*    <tbody>*/}
                {/*    <tr>*/}
                {/*      <th>*/}
                {/*        <FormCheckbox toggle small checked={this.mileageBonusSettings.isSnsJoinFirst || false} onChange={event=> this.onChangeChecked(event, this.mileageBonusSettings, ['isSnsJoinFirst'])} >*/}
                {/*          회원 최초*/}
                {/*        </FormCheckbox>*/}
                {/*      </th>*/}
                {/*      <td>*/}
                {/*        <div className="option-value">*/}
                {/*          회원가입 후 첫 번째로 작성한 포토리뷰에 추가 적립금*/}
                {/*          <FormInput value={this.mileageBonusSettings.snsJoinFirst_1 || ''} type="number" name="snsJoinFirst_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />*/}
                {/*          지급*/}
                {/*        </div>*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*      <th>*/}
                {/*        <FormCheckbox toggle small checked={this.mileageBonusSettings.isSnsFsb || false} onChange={event=> this.onChangeChecked(event, this.mileageBonusSettings, ['isSnsFsb'])} >*/}
                {/*          선착순*/}
                {/*        </FormCheckbox>*/}
                {/*        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>*/}
                {/*      </th>*/}
                {/*      <td>*/}
                {/*        <div className="option-value">*/}
                {/*          포토리뷰 선착순*/}
                {/*          <FormInput value={this.mileageBonusSettings.snsFsbNum_1 || ''} type="number" name="snsFsbNum_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />*/}
                {/*          등 까지 추가 적립금*/}
                {/*          <FormInput value={this.mileageBonusSettings.snsFsb_1 || ''} type="number" name="snsFsb_1" className="table-input-number text-center" onChange={event => this.onChangeInputs(event, this.mileageBonusSettings)} placeholder="" />*/}
                {/*          지급*/}
                {/*        </div>*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    <tr className="active">*/}
                {/*      <th>*/}
                {/*        <FormCheckbox toggle small checked={this.mileageBonusSettings.isSnsOnlyOnce || false} onChange={event=> this.onChangeChecked(event, this.mileageBonusSettings, ['isSnsOnlyOnce'])} >*/}
                {/*          추가적립금는 한 번만 지급*/}
                {/*        </FormCheckbox>*/}
                {/*        <span className="tooltip-icon"><FeatherIcon icon="alert-circle" className="nd-icon" /></span>*/}
                {/*      </th>*/}
                {/*      <td>*/}
                {/*        <div className="option-value">*/}
                {/*          <span>리뷰에서 추가적립금 종류는 최대 적립금 1번만 받도록 설정</span>*/}
                {/*          /!*<div className="btn-option">*!/*/}
                {/*          /!*  <button className="btn-add-option">*!/*/}
                {/*          /!*    <FeatherIcon icon="plus" className="nd-icon" />*!/*/}
                {/*          /!*  </button>*!/*/}
                {/*          /!*</div>*!/*/}
                {/*        </div>*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    </tbody>*/}
                {/*  </table>*/}
                {/*</div>*/}
                <div className="btn-save">
                  <Button className="save-button" onClick={event => this.updateMileageBonusSettings(event)}>저장</Button>
                </div>
              </div>
            </div>
          </div>

        </div>
    )
  }
}

export default MileageConfig;
