import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';

import './style.scss'
import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import _ from "underscore";
import moment from "moment/moment";
import ModalLoading from "../../../components/common/ModalLoading";
import {
  Button,
  ButtonGroup, Collapse,
  FormGroup,
  FormInput, FormSelect, FormTextarea,
  InputGroup,
  InputGroupAddon, Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import {toast} from "react-toastify";


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ AOA }) => ({ getStoreList:AOA.getStoreList, getStoreDetail:AOA.getStoreDetail, updateStoreMemo:AOA.updateStoreMemo, csLogin:AOA.csLogin, renewToken:AOA.renewToken, withdrawal:AOA.withdrawal }))
@inject(({ review }) => ({ removeReviewMany: review.removeReviewMany }))


@observer
class StoreList extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //list
  @observable listParams = {
    page:1, limit:20,
    displayStatus: 100,
    searchParams: {
      displayAt: 'all'
    }
  }
  @observable list = {};
  @observable searchFormValue = {
    isPaidUser: false
  }

  //modals
  @observable modals = {
    isStoreDetail: false,
    storeDetailData: {},
  };

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
  }

  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.teamUser.store;
      this.listParams.store_id = this.teamUser.selStore_id;
      if(this.store.domain === "fromday.co.kr" && this.store.email === "admin@onnomads.com" && this.store.presidentName === "윤정진") {
        this.urlParamsToListParams();
      }
      else {
        window.location.replace('/signin');
      }
    }
  };

  @action
  getStoreList = async() => {
    if(this.listParams.store_id) {
      this.list = await this.props.getStoreList(this.listParams);
      console.log(this.list.totalDocs)
      this.isLoading = false;
    }
    else {
      this.isLoading = false;
    }
  };

  urlParamsToListParams = function() {

    let searchFormValueArr = {};
    for (const key of this.urlParams.keys()) {

      if(['page'].indexOf(key) > -1 || ['limit'].indexOf(key) > -1) {
        this.listParams[key] = parseInt(this.urlParams.get(key));
      }
      else {
        if(key.indexOf('__') > -1) {
          let keyArr = key.split('__');
          if(!searchFormValueArr[keyArr[0]]) { searchFormValueArr[keyArr[0]] = {};}
          searchFormValueArr[keyArr[0]][keyArr[1]] = true;
        }
        else {
          this.searchFormValue[key] = this.urlParams.get(key);
          this.listParams.searchParams[key] = this.urlParams.get(key);
        }
      }
    }

    for(let key in searchFormValueArr) {
      if(searchFormValueArr[key].all) {
        for(let deKey in this.searchFormValue[key]) {
          this.searchFormValue[key][deKey] = true;
        }
      }
      else {
        for(let deKey in this.searchFormValue[key]) {
          if(searchFormValueArr[key][deKey]) {
            this.searchFormValue[key][deKey] = true;
          }
          else {
            this.searchFormValue[key][deKey] = false;
          }
        }
      }
      this.listParams.searchParams[key] = searchFormValueArr[key];
    }

    this.getStoreList();
  }

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'password') {
      this.teamUser[name] = value;
    }
    else {
      this.teamUser[name] = value;
    }
  };


  csLogin = async (item) => {
    let params = {
      id: item._id,
      platformId: item.platformId,
      emailId: item.email
    }
    let res = await this.props.csLogin(params);
    console.log(res);

  };

  renewToken = async (item) => {
    if(window.confirm('모든 주문 사용자의 토큰을 갱신 처리 하시겠습니까?')) {
      let params = {
        store_id: this.store._id,
      }
      let res = await this.props.renewToken(params);
      console.log(res)
    }
  }

  delAllReview = async function(item) {
    if(window.confirm('모든 리뷰를 삭체 처리 하시겠습니까?')) {
      let withDrawStore = {
        store_id: item.id,
        startDate: moment().subtract(3, 'years'),
        endDate: moment().subtract(1, 'months')
      };
      let res = await this.props.removeReviewMany(withDrawStore);
    }
  };

  withdrawal = async (item) => {
    if(window.confirm('탈퇴처리 하시겠습니까?')) {
      let withDrawStore = {
        store_id: item.id,
        reason: "탈퇴처리"
      };
      let res = await this.props.withdrawal(withDrawStore);
      console.log(res);
    }

  };

  updateStoreMemo = async (item) => {
    let params = {
      id: item._id,
      memo: item.memo
    }
    let res = await this.props.updateStoreMemo(params);
    console.log(res);
  };

  onChangeMemoValue = async (event, item) => {
    console.log(item)
    const { name, value } = event.target;
    item[name] = value;
  };

  onChangeSearchValue = async (event, obj) => {
    const { name, value, type } = event.target;
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      console.log(obj, newValue)
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.searchFormValue[name] = newValue;
      if(name == 'isDelete') {
        for(let key in this.searchFormValue.reviewDis) {
          this.searchFormValue.reviewDis[key] = !newValue;
        }
      }
    }

  };

  modalToggle = function(target, data) {
    let _this = this;
    if(!target) {
      for( var i in _this.modals) {
        if(i.indexOf('is') === 0) {
          _this.modals[i] = false;
        }
      }
    }
    else {
      _this.modals[target] = !this.modals[target];
    }

    if(data) {
      if(target === 'isStoreDetail' && data) {
        _this.modals.storeDetailData = data;

        let params = {
          store_id: data._id,
          isRefreshToken: true
        }
        this.props.getStoreDetail(params, function(res) {
          if(res.errors && res.errors.length > 0) {
            toast(res.errors[0].message);
          }
          else {
            _this.modals.storeDetailData.detail = res.data;
            console.log(res)
          }
        });

      }
      else {
        _this.modals.data = data;
      }

    }
  };



  render() {
    let _this = this;
    function Pagination() {
      let result = [];
      //let limit = _this.list.limit;
      let totalPages, curPageNum, nextPage, prevPage, startNum, endNum;
      if (_this.list && _this.list.totalPages) {
        totalPages = _this.list.totalPages;
        curPageNum = _this.list.page;
        nextPage = _this.list.nextPage;
        prevPage = _this.list.prevPage;
        startNum = (parseInt((curPageNum - 1) / 10) * 10) + 1;
        endNum = startNum + 9 < totalPages ? startNum + 9 : totalPages;
      }

      let rendering = () => {
        for (let i = startNum; i <= endNum; i++) {
          if (i == curPageNum) {
            result.push(<li key={i} className="on"><a href={"?page=" + i}>{i}</a></li>);
          } else {
            result.push(<li key={i}><a href={"?page=" + i}>{i}</a></li>);
          }
        }
        return result;
      }
      if (_this.list && _this.list.totalPages) {
        return (
            <div className="pagination">
              <ul>
                {curPageNum != 1 ?
                    <li className="btn-page-first"><a href="?page=1"><FeatherIcon icon="chevrons-left" className="nd-icon"/></a></li> : null}
                {startNum - 1 > 0 ?
                    <li className="btn-page-prev"><a href={'?page=' + (startNum - 1)}><FeatherIcon icon="chevron-left" className="nd-icon"/></a></li> : null}
                {rendering()}
                {endNum != totalPages ?
                    <li className="btn-page-next"><a href={'?page=' + (startNum + 10)}><FeatherIcon icon="chevron-right" className="nd-icon"/></a></li> : null}
                {curPageNum != totalPages ?
                    <li className="btn-page-last"><a href={'?page=' + totalPages}><FeatherIcon icon="chevrons-right" className="nd-icon"/></a></li> : null}
              </ul>
            </div>);
      } else {
        return (<></>);
      }
    }

    return (
      <div className="main-content">
        <PageTitle />

        <div className="user-list-box">
          <div className="section-box">
            <form id="" target="" className="">
              <div className="user-search-box">
                <table>
                  <tbody>
                  <tr>
                    <th>검색어</th>
                    <td>
                      <div className="search-input-box">
                        <select name="searchType" className="search-type">
                          <option value="">이름</option>
                          <option value="">주문번호</option>
                        </select>
                        <input type="text" name="keyword" className="table-input-text search-input"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>주문한 스토어</th>
                    <td>
                      <label><input type="checkbox" name="isPaidUser" onChange={event => {
                        this.onChangeSearchValue(event)
                      }} value={this.searchFormValue.isPaidUser}
                                    checked={Boolean(this.searchFormValue.isPaidUser)}/><span>주문한 스토어</span></label>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="btn-search">
                  <input type="submit" value="검색" className="search-button"/>
                </div>
              </div>
            </form>
            <div className="text-right">
              <Button outline theme="danger" onClick={e => this.renewToken()}>토큰갱신</Button>
            </div>

          </div>
          <div className="section-box">
            <form id="" target="" className="">
              <div className="user-list-table">
                <div className="user-pick-list">
                  <div className="pick-list-count">
                    검색결과 <span>{this.list.totalDocs}</span>개
                  </div>
                  <div className="choice-num-view">
                    <select name="" className="">
                      <option value="">100개씩 보기</option>
                      <option value="">75개씩 보기</option>
                      <option value="">50개씩 보기</option>
                      <option value="">25개씩 보기</option>
                    </select>
                  </div>
                </div>
                <table className="store-list">
                  <colgroup>
                    <col width=""/>
                    <col width="15%"/>
                    <col width="7%"/>
                    <col width="7%"/>
                    <col width="10%"/>
                    <col width="7%"/>
                    <col width="5%"/>
                    <col width="5%"/>
                    <col width="20%"/>
                  </colgroup>
                  <thead>
                  <tr>
                    {/*<th>플렛폼 정보</th>*/}
                    <th>고객 정보</th>
                    <th>연락 정보</th>
                    <th>주문</th>
                    <th>서비스 종료일</th>
                    <th>토큰 상태</th>
                    <th>가입일</th>
                    <th>상세</th>
                    <th>로그인</th>
                    <th>처리기능</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.list && this.list.docs && this.list.docs.length > 0 ?
                      this.list.docs.map((item, i) => (
                          // <tr key={i}
                          //     className={moment(item.cafe24TokenRefreshExpiresAt).isAfter(moment()) ? (moment(item.cafe24TokenExpiresAt).isAfter(moment()) ? "expierd" : "") : "now_use"}>
                          <tr key={i}>
                            {/*<td>{item.platform} / {item.platformId}</td>*/}
                            <td>{item.name}({item.presidentName}) / {item.domain ? (
                                <a href={"https://" + item.domain} target="_blank">{item.domain}</a>
                            ) : (
                                <a href={"https://" + item.platformId + ".cafe24.com"}
                                   target="_blank">{item.platformId + ".cafe24.com"}</a>
                            )} / {item.platformId}</td>{/*고객정보*/}
                            <td>{item.notificationEmail ? item.notificationEmail : item.email} / {item.tel ? item.tel : "-"}</td>{/*연락 정보*/}
                            <td>{item.isPaidUser ? item.orderName + " / " + moment(item.useExpiryDate).format('YYYY.MM.DD') : "무료"}</td>{/*주문*/}
                            <td>{item.isPaidUser ? moment(item.useExpiryDate).format('YYYY.MM.DD') + "/ " +  moment(item.useExpiryDate).diff(moment(), 'days') : ''}</td>{/*서비스 종료일*/}
                            <td>{moment(item.cafe24TokenRefreshExpiresAt).format("YYYY-MM-DD")} / {moment(item.cafe24TokenRefreshExpiresAt).diff(moment(), 'days')}</td>{/*토큰 상태*/}
                            <td>{moment(item.createdAt).format("YYYY-MM-DD")} {item.isApplyDelStoreAccount ? " - " + item.isApplyDelStoreAccount : ''}</td>{/*가입 날짜*/}
                            <td className="text-center">
                              <ButtonGroup>
                                <Button onClick={e => this.modalToggle('isStoreDetail', item)}>상세</Button>
                              </ButtonGroup>
                            </td>
                            <td className="text-center">
                              <ButtonGroup>
                                <Button onClick={e => this.csLogin(item)}>login</Button>
                              </ButtonGroup>
                            </td>
                            <td className="text-left">
                              <div>
                                <InputGroup>
                                  <FormInput name="memo" placeholder="메모" onChange={event => {
                                    this.onChangeMemoValue(event, item)
                                  }} value={item.memo} className="table-input-text"/>
                                  <InputGroupAddon type="append"><Button theme="secondary" onClick={e => {
                                    this.updateStoreMemo(item)
                                  }}><i className="material-icons">업데이트</i></Button></InputGroupAddon>
                                </InputGroup>
                              </div>
                              {item.isApplyDelStoreAccount ? (
                                  <div className="btn-wrap">
                                    <Button outline theme="danger"
                                            onClick={event => this.withdrawal(item)}>탈퇴처리</Button>
                                  </div>
                              ) : null}
                            </td>
                          </tr>))
                      :
                      <tr>
                        <td colSpan={9} className="text-center">가입한 스토어가 없습니다</td>
                      </tr>
                  }

                  </tbody>
                </table>
                <Pagination />
              </div>
            </form>
          </div>
        </div>

        <Modal size="lg" className='modal-review-write-form' open={this.modals.isStoreDetail} toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>스토어 상세 보기</ModalHeader>
          <ModalBody className="history-window-popup">
            <div className="history-popup-contents">
              {this.modals.storeDetailData ? (
                  <table>
                    <colgroup>
                      <col style={{width: '15%'}}/>
                      <col style={{width: '35%'}}/>
                      <col style={{width: '15%'}}/>
                      <col style={{width: '35%'}}/>
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>쇼핑몰</th>
                      <td>{this.modals.storeDetailData.name}({this.modals.storeDetailData.presidentName})
                        / {this.modals.storeDetailData.domain ? (
                            <a href={"https://" + this.modals.storeDetailData.domain}
                               target="_blank">{this.modals.storeDetailData.domain}</a>
                        ) : (
                            <a href={"https://" + this.modals.storeDetailData.platformId + ".cafe24.com"}
                               target="_blank">{this.modals.storeDetailData.platformId + ".cafe24.com"}</a>
                        )} / {this.modals.storeDetailData.platformId}</td>
                      <th>주문수</th>
                      <td>{this.modals.storeDetailData.detail ? this.modals.storeDetailData.detail.getMonthlySales : 0}</td>
                    </tr>
                    <tr>
                      <th>주문수 시작일</th>
                      <td>{this.modals.storeDetailData.detail ? this.modals.storeDetailData.detail.getMonthlySalesStartDate : ''}</td>
                      <th>주문수 종료일</th>
                      <td>{this.modals.storeDetailData.detail ? this.modals.storeDetailData.detail.getMonthlySalesEndDate : ''}</td>
                    </tr>
                    <tr>
                      <th>리뷰 수</th>
                      <td>{this.modals.storeDetailData.detail ? this.modals.storeDetailData.detail.reviewCount : 0}
                        <Button className="ml-2" theme="danger"
                                onClick={event => this.delAllReview(this.modals.storeDetailData)}>전체삭제</Button></td>
                      <th>상품 수</th>
                      <td>{this.modals.storeDetailData.detail ? this.modals.storeDetailData.detail.productCount : 0}</td>
                    </tr>
                    <tr>
                      <th>id</th>
                      <td colSpan={3}>{this.modals.storeDetailData._id}</td>
                    </tr>
                    </tbody>
                  </table>) : null}

              <div className="text-center">
                {/*<Button onClick={event => this.updateReview(this.modals.data)} >리뷰작성</Button>*/}
                <Button className="ml-2" theme="light" onClick={event => this.modals.isStoreDetail = false}>닫기</Button>
              </div>
            </div>

          </ModalBody>
        </Modal>
        <ModalLoading isLoading={this.isLoading}/>
      </div>
    )
  }
}

export default StoreList;
