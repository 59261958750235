import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import './style.scss'
import {reactLocalStorage as Storage} from "reactjs-localstorage";

@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, isLogin: teamUser.isLogin }))
@observer
class Starter extends Component {

  @observable teamUser = {
    userId: '',
    password: ''
  };

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){
    // if(this.props.isLogin !== prevProps.isLogin){
    //   this.props.doLogOut();
    // }
  }

  @action
  init = async (key) => {
    console.log(this.props.isLogin)
    if(this.props.isLogin) {
      this.props.history.push('/reviewList');
    }
    else {
      this.props.history.push('/signIn');
    }
  };

  render() {
    return (
      <>
        <div className="sign-content">
          <div className="sign-content-bg">
            <span className="bg-cont1"></span>
            <span className="bg-cont2"></span>
            <span className="bg-cont3"></span>
            <span className="bg-cont4"></span>
          </div>
        </div>
      </>
    )
  }
}

export default Starter;
