import React from 'react';
import {ColorRing} from "react-loader-spinner";

const ModalLoading = ( props ) => {
  let isLoading = props.isLoading;


  return(
    <>
    { isLoading ? (
    <div className="overlay" >
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#007bff', '#252B3B']}
      />
    </div>) : null}
    </>
  )
}

export default ModalLoading;
