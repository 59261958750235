import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";

import Chart from "../../utils/chart";

class ReviewsSentiment extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {

    let chartData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: [68.3, 14.2],
          //data: [this.props.positiveReviewCount, this.props.negativeReviewCount],
          backgroundColor: [
            "rgba(0,123,255,0.9)",
            "rgba(0,123,255,0.5)",
            // "rgba(0,123,255,0.3)"
          ]
        }
      ],
      labels: ["긍정", "부정"]
    }

    const chartConfig = {
      type: "pie",
      data: chartData,
      options: {
        legend: {
          position: "bottom",
          labels: {
            padding: 25,
            boxWidth: 20
          }
        },
        cutoutPercentage: 0,
        tooltips: {
          custom: false,
          mode: "index",
          position: "nearest"
        }
      }
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {



    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">최근 긍정/부정 리뷰 비율</h6>
        </CardHeader>
        <CardBody className="d-flex py-0">
          <canvas
            height="220"
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
        <CardFooter className="border-top">
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <FormSelect*/}
          {/*      size="sm"*/}
          {/*      value="last-week"*/}
          {/*      style={{ maxWidth: "130px" }}*/}
          {/*      onChange={() => {}}*/}
          {/*    >*/}
          {/*      <option value="last-week">Last Week</option>*/}
          {/*      <option value="today">Today</option>*/}
          {/*      <option value="last-month">Last Month</option>*/}
          {/*      <option value="last-year">Last Year</option>*/}
          {/*    </FormSelect>*/}
          {/*  </Col>*/}
          {/*  <Col className="text-right view-report">*/}
          {/*    /!* eslint-disable-next-line *!/*/}
          {/*    <a href="#">View full report &rarr;</a>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </CardFooter>
      </Card>
    );
  }
}

export default ReviewsSentiment;
