import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Store } from "../../flux";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {Link} from 'react-router-dom';

import FeatherIcon from 'feather-icons-react';
import Shepherd from 'shepherd.js'; // 'react-shepherd' 패키지에서 Shepherd 클래스를 가져옵니다.
import '../../../node_modules/shepherd.js/dist/css/shepherd.css';
import './StartGuide.scss'

@inject(({ common }) => ({ getCurNav: common.getCurNav }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me}))
@inject(({ company }) => ({ updateStore:company.updateStore  }))
@observer
class StartGuide extends React.Component {

  @observable emailId = '';
  @observable isLogin = false;
  @observable curPath = '';

  constructor(props) {
    super(props);
    this.init();
    this.onChange = this.onChange.bind(this);
    this.updateStore = this.updateStore.bind(this);
  }

  @action
  init = async (key) => {

    let _this = this;
    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.emailId = this.props.me.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};

      if(!this.store.settingProg) {
        this.store.settingProg = {
          isEnd: false,
          reviewWrite: false,
          reviewWriteIn: {
            item_1: false,
            item_2: false,
            item_3: false,
            item_4: false,
            item_5: false,
            item_6: false,
          },
        }
        _this.updateStore();
      }
      else {
        if(!this.store.settingProg.reviewWriteIn) {
          this.store.settingProg.reviewWrite = false;
          this.store.settingProg.reviewWriteIn = {
            item_1: false,
            item_2: false,
            item_3: false,
            item_4: false,
            item_5: false,
            item_6: false,
          }
        }

        let sKeys = ['reviewWrite'];
        let isEnd = true;
        for(let i in sKeys) {
          let isSubEnd = true;
          let isSubItemKey = sKeys[i] + 'In';
          for(let j in this.store.settingProg[isSubItemKey]) {
            if(!this.store.settingProg[isSubItemKey][j]) {
              isSubEnd = false;
            }
          }

          if(this.store.settingProg[sKeys[i]] !== isSubEnd) {
            this.store.settingProg[sKeys[i]] = isSubEnd;
            _this.updateStore();
          }
          if(!this.store.settingProg[sKeys[i]]) {
            isEnd = false;
          }
        }
      }


      this.curPath = this.props.getCurNav().submenu && this.props.getCurNav().submenu.name ? this.props.getCurNav().submenu.name : '';
    }

    //작성 설정 /reviewWriteConfig
    _this.tour = new Shepherd.Tour({
      useModalOverlay: false, // 모달 오버레이 사용 여부
      defaultStepOptions: {
        classes: 'shepherd-theme-arrows', // 투어 스타일 클래스
        scrollTo: { behavior: 'smooth', block: 'center' }, // 스크롤 사용 여부
        scrollToHandler: function(){
          console.log('scrollToHandler')
        },
        cancelIcon: {
          enabled: true,
          iconClasses: 'custom-cancel-icon', // 커스텀 아이콘 클래스
          useIcon: '<i class="fas fa-times"></i>', // Font Awesome의 X 아이콘을 사용
          attachTo: 'top-right',
          title: '취소',
          events: {
            click: () => {
              // 아이콘이 클릭될 때 실행될 동작을 정의
              _this.tour.cancel();
            },
          },
        },
      },
    });

    _this.tour.addStep({
      id: 'reviewWriteIn_item1',
      text: '카페24에서 답글을 작성시 요구되는 관리자의 이름과 비밀번호가 필요합니다.<br />비밀번호는 추후 답글의 내용을 수정 할 때 필요합니다.',
      attachTo: {
        element: '#reviewWriteIn_item1',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_1 = true;
            _this.tour.show('reviewWriteIn_item2')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'reviewWriteIn_item2',
      text: '답글 자동작성 여부와 시작 및 종료시간을 설정합니다.',
      attachTo: {
        element: '#reviewWriteIn_item2',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_2 = true;
            _this.tour.show('reviewWriteIn_item3')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'reviewWriteIn_item3',
      text: '시작 시간 부터 종료 시간 까지의 작성 주기를 설정합니다.',
      attachTo: {
        element: '#reviewWriteIn_item3',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_3 = true;
            _this.tour.show('reviewWriteIn_item4')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'reviewWriteIn_item4',
      text: '답글 내용에 리뷰 작성자와 상품명 포함 여부를 설정합니다.',
      attachTo: {
        element: '#reviewWriteIn_item4',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_4 = true;
            _this.tour.show('reviewWriteIn_item5')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'reviewWriteIn_item5',
      text: 'AI가 답글을 생성시 학습 할 예시 답글을 최대 3개를 입력합니다.',
      attachTo: {
        element: '#reviewWriteIn_item5',
        on: 'top',
      },
      buttons: [
        {
          text: '다음',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_5 = true;
            _this.tour.show('reviewWriteIn_item6')
          },
        }
      ]
    });

    _this.tour.addStep({
      id: 'reviewWriteIn_item6',
      text: '리뷰의 별점에 따른 AI작성 또는 고정답글을 설정하고 이에 따른 학습답글 또는 고정답글을 입력합니다.',
      attachTo: {
        element: '#reviewWriteIn_item6',
        on: 'top',
      },
      buttons: [
        {
          text: '완료',
          action: function () {
            _this.store.settingProg.reviewWriteIn.item_6 = true;
            _this.updateStore();
            _this.tour.cancel();
          },
        }
      ]
    });

  };

  updateStore = async (event) => {

    let updateStore = {
      id: this.store._id,
      settingProg: this.store.settingProg,
    }

    this.props.updateStore( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {

      }
    });
  }

  noDisplaySguide = async (event) => {
    let cf = window.confirm('설정 가이드를 그만보시겠습니까?');
    if(cf) {
      this.store.settingProg.isEnd = true;
      this.updateStore();
    }
    else {
      return false;
    }

  }

  forceIndexGuideShow = async (event) => {
    this.curPath = '';
  }

  componentDidUpdate(prevProps, prevState){
    console.log(prevProps)
    console.log(prevState)
  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {

  }


  startTour = (startStepId) => {
    this.tour.show(startStepId);
  };


  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );




    return (
        <>
        {
          this.store && this.store.settingProg && !this.store.settingProg.isEnd && false ? (
            <div className="start-guide">
              {(() => {
                switch (this.curPath) {
                  case "reviewWriteConfig":
                    return (
                        <>
                          <h3><FeatherIcon icon="flag" className="nd-icon" size="17" fill="#8FC322"/>답글 작성 설정 가이드</h3>
                          <p>답글 작성에 필요한 설정을 진행합니다.</p>
                          <div className="prograss-bars">
                            {this.store.settingProg.reviewWriteIn.item_1 ? <span className="active"></span> :
                                <span></span>}
                            {this.store.settingProg.reviewWriteIn.item_2 ? <span className="active"></span> :
                                <span></span>}
                            {this.store.settingProg.reviewWriteIn.item_3 ? <span className="active"></span> :
                                <span></span>}
                            {this.store.settingProg.reviewWriteIn.item_4 ? <span className="active"></span> :
                                <span></span>}
                            {this.store.settingProg.reviewWriteIn.item_5 ? <span className="active"></span> :
                                <span></span>}
                            {this.store.settingProg.reviewWriteIn.item_6 ? <span className="active"></span> :
                                <span></span>}
                          </div>
                          <ul>
                            <li onClick={event => this.startTour('reviewWriteIn_item1')}>{this.store.settingProg.reviewWriteIn.item_1 ?
                                <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322"/> :
                                <FeatherIcon icon="circle" className="nd-icon" size="17"/>}
                              답글 작성자의 이름과 비밀번호를 입력하세요.(비밀 번호는 삭제시 필요합니다.)
                            </li>
                            <li onClick={event => this.startTour('reviewWriteIn_item2')}>{this.store.settingProg.reviewWriteIn.item_2 ?
                                <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322"/> :
                                <FeatherIcon icon="circle" className="nd-icon" size="17"/>}
                              답글 자동 여부, 시간을 설정 합니다.
                            </li>
                            <li onClick={event => this.startTour('reviewWriteIn_item3')}>{this.store.settingProg.reviewWriteIn.item_3 ?
                                <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322"/> :
                                <FeatherIcon icon="circle" className="nd-icon" size="17"/>}
                              답글 주기를 설정 합니다.
                            </li>
                            <li onClick={event => this.startTour('reviewWriteIn_item4')}>{this.store.settingProg.reviewWriteIn.item_4 ?
                                <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322"/> :
                                <FeatherIcon icon="circle" className="nd-icon" size="17"/>}
                              답글의 리뷰 작성자 이름과 상품명의 포함 여부와 문장의 길이에 대한 설정을 합니다.
                            </li>
                            <li onClick={event => this.startTour('reviewWriteIn_item5')}>{this.store.settingProg.reviewWriteIn.item_5 ?
                                <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322"/> :
                                <FeatherIcon icon="circle" className="nd-icon" size="17"/>}
                              답글 작성시 학습 할 예시 답글을 최대 3개 입력해 주세요.
                            </li>
                            <li onClick={event => this.startTour('reviewWriteIn_item6')}>{this.store.settingProg.reviewWriteIn.item_6 ?
                                <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322"/> :
                                <FeatherIcon icon="circle" className="nd-icon" size="17"/>}
                              리뷰의 별점에 따른 답글을 설정하세요.
                            </li>
                          </ul>
                          <p className="noDisplaySguide" onClick={event => this.noDisplaySguide()}>가이드 그만보기</p>
                        </>
                    );
                    break;
                  default:
                    return (
                        <>
                          <h3><FeatherIcon icon="flag" className="nd-icon" size="17" fill="#8FC322"/>시작하기</h3>
                          <p>아래 설치 지침에 따라 셋팅을 시작하십시오.</p>
                          <div className="prograss-bars">
                          {this.store.settingProg.reviewWrite ? <span className="active"></span> : <span></span>}
                        </div>
                        <ul>
                          <li>{this.store.settingProg.reviewWrite ? <FeatherIcon icon="check-circle" className="nd-icon" size="17" fill="#8FC322" /> : <FeatherIcon icon="circle" className="nd-icon" size="17" />}
                            <Link to="/reviewWriteConfig">리뷰의 답글을 자동으로 작성하기 위한 설정이 필요합니다.</Link>
                          </li>
                        </ul>
                        <p className="noDisplaySguide" onClick={event=> this.noDisplaySguide()} >가이드 그만보기</p>
                      </>
                    );
                }
              })()}
            </div>
          ) : null
        }
        </>
    );
  }
}

StartGuide.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

StartGuide.defaultProps = {
  hideLogoText: false
};

export default StartGuide;
