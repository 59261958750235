import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import {Link} from "react-router-dom";
import './style.scss'


//https://gaemi606.tistory.com/entry/React-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EC%A0%95%EB%B3%B4-%EC%97%86%EC%9D%84-%EA%B2%BD%EC%9A%B0-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%ED%8E%98%EC%9D%B4%EC%A7%80%EB%A1%9C-redirect%ED%95%98%EA%B8%B0-react-router-PrivateRoute
@inject(({ teamUser }) => ({ doSignup: teamUser.doSignup }))
@observer
class SignUp extends Component {

  @observable teamUser = {
    userId: '',
    password: '',
    passwordConfirm: '',
  };

  constructor(props) {
    super(props);
    this.init()
  }

  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {

    this.doSignUp = this.doSignUp.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);

  };

  doSignUp() {
    let _this = this;
    let params = {
      emailId : this.teamUser.userId,
      password : this.teamUser.password,
    };
    if(params.password === this.teamUser.passwordConfirm) {
      this.props.doSignup(params, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          _this.props.history.push('/signIn');
        }
      });
    }
    else {

    }
  }

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.teamUser[name] = value;
  };

  render() {
    return (
      <>
        <div className="sign-content">
          <div className="sign-content-bg">
            <span className="bg-cont1"></span>
            <span className="bg-cont2"></span>
            <span className="bg-cont3"></span>
            <span className="bg-cont4"></span>
          </div>
          <div className="sign-content-card">
            <h3>회원가입</h3>
            <div className="sign-form">
              <dl>
                <dt><input name="userId" type="text" className="" placeholder="이메일 아이디" value={this.teamUser.userId} onChange={event => this.onChangeValue(event)} /></dt>
              </dl>
              <dl>
                <dt><input name="password" type="password" className="" placeholder="비밀번호" value={this.teamUser.password} onChange={event => this.onChangeValue(event)} /></dt>
              </dl>
              <dl>
                <dt><input name="passwordConfirm" type="password" className="" placeholder="비밀번호 확인" value={this.teamUser.passwordConfirm} onChange={event => this.onChangeValue(event)} /></dt>
              </dl>
            </div>
            <div className="btn-box">
              <input type="button" value="회원가입" className="save-button" onClick={event => this.doSignUp()}/>
              <div className="sign-in">
                <span>이미 계정이 있으신가요?</span><Link to={"/signIn"}><strong>로그인</strong></Link>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SignUp;
