import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './UploaderBanner.scss';
import {decorate, observable, action, isObservableObject, toJS} from 'mobx';
import { observer, inject } from 'mobx-react';
import FeatherIcon from "feather-icons-react";
import {Button} from "shards-react";

const maxFiles = 6;
const maxSizeMb = 4.5; // 5MB
const maxSize = maxSizeMb * 1024 * 1024; // 5MB

const getMediaType = (file) => {
    let type;
    if(file.type.indexOf('image/') > -1) {
        type = 'image';
    }
    else if(file.type.indexOf('video/') > -1) {
        type = 'video';
    }
    return type;
};

const byteToMb = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + 'MB';
}

function UploaderBanner(props) {

    const [files, setFiles] = useState([]);
    const [orgfiles, setOrgFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: maxFiles,
        multiple: true,
        onDrop: async (acceptedFiles, fileRejections) => {
            await setOrgFiles(prevOrgFiles => [...prevOrgFiles, ...acceptedFiles]);
            const newFiles = acceptedFiles.reduce((acc, file) => {
                if (file.size > maxSize) {
                    alert(file.name + ' 파일이 너무 큽니다. 최대 5MB까지 업로드 가능합니다.');
                    return acc; // 파일을 결과 배열에 추가하지 않고 현재의 누적값을 그대로 반환
                } else {
                    // 파일 정보를 새로운 객체로 만들어 배열에 추가
                    const fileWithMetaData = {
                        ...file,
                        id: file.name + file.lastModified, // 고유 식별자 생성
                        name: file.name,
                        size: file.size, // 파일 사이즈를 MB 단위로 변환
                        preview: URL.createObjectURL(file) // 파일 미리보기 URL 생성
                    };
                    acc.push(fileWithMetaData); // 조건에 맞는 파일만 누적 배열에 추가
                    return acc;
                }
            }, []);
            await setFiles(prevFiles => [...prevFiles, ...newFiles]);
            // const newFiles = acceptedFiles.map(file => {
            //     return {
            //     ...file,
            //     id: file.name + file.lastModified, // 고유 식별자 생성
            //     name: file.name,
            //     size: byteToMb(file.size),
            //     preview: URL.createObjectURL(file)
            //     }
            // });
            // await setFiles(prevFiles => [...prevFiles, ...newFiles]);
        }
    });

    // Clean up URLs to prevent memory leaks
    useEffect(() => {
        props.getFiles(orgfiles);
        props.getPreviewFiles(files);
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    // 파일 삭제 함수
    const removeFile = fileName => {
        setOrgFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };

    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <FeatherIcon icon="upload-cloud" size="70" className="nd-icon upload-icon"/>
                <p>이미지 배너 추가를 위해</p>
                <p>클릭 혹은 파일을 이곳에 드롭하세요.</p>
            </div>
        </section>
    );
}

export default UploaderBanner;
