import React from "react";
import "./SignOut.scss";

const SignOutLayout = ({ children }) => (
  <div className="wrap-sign-out">
    {children}
  </div>
);

SignOutLayout.propTypes = {

};

SignOutLayout.defaultProps = {

};

export default SignOutLayout;
