import React, {Component} from 'react';
import './style.scss'
import {TermsAndPrivacy} from "../../../utils/termsAndPrivacy";

class PrivateAgree extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="welcome-content agree-content">
          <div className="welcome-content-bg">
            <div className="welcome-content-wrap">
              <div className={"terms-privacy"}
                   dangerouslySetInnerHTML={{__html: TermsAndPrivacy.privacyPolicy}}></div>
            </div>
          </div>
        </div>
    )
  }
}

export default PrivateAgree;
