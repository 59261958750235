import React, { Component, useState } from 'react'
import DaumPostcode from "react-daum-postcode";

import './ModalAddrPost.scss'

const ModalAddrPost = ( props ) => {
  let isPopupOpen = props.isPopupOpen;
  // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
  const handlePostCode = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    props.setAddress(data);
    // console.log(data)
    // console.log(fullAddress)
    // console.log(data.zonecode)
    props.onClose()
  }

  return(
    <>
      <div className='daum-postcode'>
        <DaumPostcode  onComplete={handlePostCode} style={{width:'600px', height:'600px'}} />
        <button type='button' onClick={() => {props.onClose()}} className='btn-table btn-info-register btn-close'>닫기</button>
      </div>
      <div className={isPopupOpen ? "layer-black show" : "layer-black hide"} onClick={e => {props.onClose()} }></div>
    </>

  )
}

export default ModalAddrPost;
