var moment = require("moment/moment");
exports.MileageCalculator = {
  reviewMileageConfig: {},
  mileageSettings: {},
  mileageBonusSettings: {},
  isMember: '',
  isGuest: '',
  isRate: false,
  rate: 1,
  orderAmount: 0,
  init: function (data, config) {
    this.reviewMileageConfig = config;
    // console.log(config)
    this.mileageBonusSettings = config.mileageBonusSettings;
    if(config.mileageSettings.isRate) {
      this.isRate = true;
      this.orderAmount = parseInt(data.reviewHolder_id && data.reviewHolder_id.orderAmount ? data.reviewHolder_id.orderAmount : data.orderAmount);
    }

    this.setMileageSettings(data);
  },
  setMileageSettings: function (data) {
    this.isMember = data.isMember;
    this.isGuest = data.isGuest;
    if(this.isMember && !this.isGuest) {
      this.mileageSettings = this.reviewMileageConfig.mileageSettings.member;
    }
    else {
      this.mileageSettings = this.reviewMileageConfig.mileageSettings.noMember;
    }
  },
  getReviewType: function(data) {
    // image, instagram, movie, text
    //data.reviewType = data.reviewType ? data.reviewType : 'text';
  },
  getUnpaid: function(data) {
    let mileage;
    let payMileageStatus = data.payMileageStatus;

    switch (payMileageStatus){
      case '1' :
      case 1 :
        if(this.isRate) {
          mileage = this.mileageSettings[data.reviewType] ? (this.orderAmount * this.mileageSettings[data.reviewType] / 100) : 0;
        }
        else {
          mileage = this.mileageSettings[data.reviewType] ? this.mileageSettings[data.reviewType] : 0;
        }

        break;
      case '2' :
      case 2 :
        mileage = 0;
        break;
      case '90' :
      case 90 :
        mileage = 0;
        break;
      default :
        mileage = 0;
        break;
    }

    return mileage;
  },
  getBonus: function(data) {
    let bonus = {
      // payMileageStatusStr:this.getPayMileageStatusStr(data.payBonusMileageStatus)
    };
    //회원최초
    bonus.joinFirst = 0;
    // console.log(this.mileageBonusSettings)
    // console.log(data)
    //if((this.mileageBonusSettings.joinFirst && this.mileageBonusSettings.joinFirst.isUse) && data.writerReviewNo == 0) {
    if((this.mileageBonusSettings.joinFirst && this.mileageBonusSettings.joinFirst.isUse)) {
      bonus.joinFirst = parseInt(this.mileageBonusSettings.joinFirst.mileage_1);
    }
    //선착순
    bonus.fsb = 0;
    if((this.mileageBonusSettings.fsb && this.mileageBonusSettings.fsb.isUse) && data.reviewNo <= this.mileageBonusSettings.fsb.range_1) {
      bonus.fsb = parseInt(this.mileageBonusSettings.fsb.mileage_1);
    }
    //이미지 수
    bonus.perImg = 0;
    if((this.mileageBonusSettings.perImg && this.mileageBonusSettings.perImg.isUse) && data.images && data.images.length >= this.mileageBonusSettings.perImg.range_1) {
      bonus.perImg = parseInt(this.mileageBonusSettings.perImg.mileage_1);
    }
    //글 수
    bonus.perText = 0;
    if((this.mileageBonusSettings.perText && this.mileageBonusSettings.perText.isUse) && data.contents.length >= this.mileageBonusSettings.perText.range_1) {
      bonus.perText = parseInt(this.mileageBonusSettings.perText.mileage_1);
    }
    //조기 작성
    bonus.early = 0;
    if((this.mileageBonusSettings.early && this.mileageBonusSettings.early.isUse)) {
      let orderDate = moment(data.userOrder_id.orderData);
      let reviewDate = moment(data.createdAt);
      if(reviewDate.diff(orderDate, 'days') <= this.mileageBonusSettings.early.range_1) {
        bonus.early = parseInt(this.mileageBonusSettings.early.mileage_1);
      }
    }

    //하나만 지급
    let bonusOnlyOne = {
      mileage: 0,
    }
    console.log(data.mileageData.bonus.bonusSum)
    if(data.mileageData.bonus.bonusSum.key != 'total') {
      for(let key in bonus) {
        if(bonusOnlyOne.mileage < bonus[key] || !bonusOnlyOne.key) {
          bonusOnlyOne.key = key;
          bonusOnlyOne.mileage = bonus[key]
        }
      }
    }
    else {
      for(let key in bonus) {
        bonusOnlyOne.mileage += bonus[key];
      }
      bonusOnlyOne.key = 'total';
    }
    // console.log(bonusOnlyOne)
    bonus.bonusOnlyOne = bonusOnlyOne;
    bonus.payMileageStatusStr = this.getPayMileageStatusStr(data.payBonusMileageStatus);
    return bonus;
  },
  getPayMileageStatusStr: function(payMileageStatus) {
    let str = '';
    switch (payMileageStatus){
      case 1 :
        str = '지급예정';
        break;
      case 2 :
        str = '지급대기';
        break;
      case 3 :
        str = '비회원 지급대기';
        break;
      case 4 :
        str = '지급완료';
        break;
      case 90 :
        str = '지급취소';
        break;
      case 91 :
        str = '지급경고';
        break;
      case 92 :
        str = '지급제한';
        break;
      case 93 :
        str = '지급포인트없음';
        break;
      case 95 :
        str = '관리자에서 확인요망';
        break;
      case 81 :
        str = '회수완료';
        break;
      case 82 :
        str = '회수실';
        break;
      default :
        str = '?';
        break;
    }
    return str;
  },
  total: function(data, reviewMileageConfig) {
    this.init(data, reviewMileageConfig);
    let result = {
      unpaid: this.getUnpaid(data),
      bonus: this.getBonus(data),
    }

    // console.log('mileageCalculator.js result', result);
    return result;
  }
};
