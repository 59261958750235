import React from "react";
import PropTypes from "prop-types";
import {inject} from "mobx-react";
import {action, observable} from "mobx";
import './PageTitle.scss'

import {Store} from "../../flux";

@inject(({ common }) => ({ getCurNav: common.getCurNav }))
class PageTitle extends React.Component {

  @observable navInfo = this.props.getCurNav();

  constructor(props) {
    super(props);

    this.init();
  }

  @action
  init = async (key) => {

     //console.log(this.navInfo)

  };


  render() {
    let _this = this;

    return (
      <div className="content-top-area">
        <div className="location">
          <span>{this.navInfo.mainMenu.label}</span>

          {this.navInfo.submenu ? (
            <span>
              {this.navInfo.submenu && this.navInfo.submenu.middleMenu ? (
                <>{this.navInfo.submenu.middleMenu} - </>
              ) : null }
              {this.navInfo.submenu.label}</span>
            ) : null }
        </div>
        {this.navInfo.submenu ? (
          <div className="content-title">
            <h2>{this.navInfo.submenu.label}</h2>
          </div>
        ) : null }
      </div>
    )
  }
}

PageTitle.propTypes = {
  info: PropTypes.object,
  hideLogoText: PropTypes.bool
};

PageTitle.defaultProps = {
  info: {},
  hideLogoText: false
};

export default PageTitle;
