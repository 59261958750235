import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";
import ReactDom from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ModalAddrPost from '../../../components/common/ModalAddrPost';

import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

//inport images
import noImg from "../../../images/img/no_img.jpg";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Button, FormInput, FormSelect, InputGroup, InputGroupAddon} from "shards-react";
import moment from "moment/moment";


const PopupDom = ({ children }) => {
  const el = document.getElementById('popupDom');
  return ReactDom.createPortal(children, el);
};

let model = {
  store: {
    name: '',
    domain: '',
    logo: '',
    platform: '',
    platformId: '',
    addrMain: '',
    addrSub: '',
    presidentName: '',
    tel: '',
    email: '',
    notificationEmail: '',
    customerService: {},
    privacyOfficer: {},
    pTeamUser_id: ''
  }
}

// model.store = {
//   name: '온노마드',
//   domain: 'http://onnomad.co.kr/',
//   logo: '',
//   platform: 'nhn_godo',
//   platformId: '',
//   addrMain: '서울시 성동구 상원1길 25 ',
//   addrSub: '4160호 (주)온노마드',
//   presidentName: '윤정진',
//   tel: '02-6417-7000',
//   email: '',
//   notificationEmail: 'godrium@naver.com',
//   customerService: {},
//   privacyOfficer: {},
//   pTeamUser_id: ''
// }

@inject(({ common }) => ({ setIsShowPopup: common.setIsShowPopup, isShowModal: common.isShowModal, getIsShowPopup: common.getIsShowPopup  }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, getTeamUserList: teamUser.getTeamUserList, delTeamUser:teamUser.delTeamUser }))
@inject(({ company }) => ({ updateStore:company.updateStore, applyDelStoreAccount : company.applyDelStoreAccount  }))

@observer
class CompanyInfoEdit extends Component {

  //basic
  @observable store = {};

  @observable teamUser = {};
  @observable permissions = {}
  // @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  @observable listParams = {page:1, limit:5};
  @observable boardList = [];
  @observable isPopupOpen = false;

  constructor(props) {
    super(props);
    this.init()

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.teamUser.email = this.teamUser.email ? this.teamUser.email : this.teamUser.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};
      console.log(this.store.isApplyDelStoreAccount)
      this.store.useExpiryDateDiffNow = moment(moment(this.store.useExpiryDate).format('YYYY-MM-DD')).diff(moment(), 'days')+1;
      this.permissions = this.props.me.permissions;
    }
    else {

      if(Storage.get('token')) {


      }
    }


    this.onChangeValue = this.onChangeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.delTeamUser = this.delTeamUser.bind(this);
    this.updateStore = this.updateStore.bind(this);
    this.openPostCode = this.openPostCode.bind(this);
    this.closePostCode = this.closePostCode.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.getTeamUserList = this.getTeamUserList.bind(this);
  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.teamUser.email = this.teamUser.email ? this.teamUser.email : this.teamUser.emailId;
      this.store = this.props.me.store ? this.props.me.store : model.store;
      this.listParams.store_id = this.store._id;


      this.permissions = this.props.me.permissions;
      if(this.teamUser.isSuper && this.listParams.store_id) {
        //this.getTeamUserList();
      }
    }
    else {

      if(Storage.get('token')) {
        //todo 재확인 - 로그인 후 해당 페이지로 넘어 오는 경우 authCheck() 함수 미샐행 할 수 있게.
        //this.props.authCheck();
      }
      // else {
      //   window.location.replace('signIn');
      // }

    }
  };

  @action
  getTeamUserList =async() => {
    this.boardList = await this.props.getTeamUserList(this.listParams);
  };


  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.store[name] = value;
  };

  onChangeSearchValue = async (event, obj) => {
    const { name, value, type } = event.target;
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.searchFormValue[name] = newValue;
      if(name == 'isDelete') {
        for(let key in this.searchFormValue.reviewDis) {
          this.searchFormValue.reviewDis[key] = !newValue;
        }
      }
    }

  };

  @action
  setAddress = (data) => {
    this.store['addrMain'] = data.address;
  };



  @action
  openPostCode = async (event) => {
    this.isPopupOpen = true;
    // this.props.setIsShowPopup(true);
  }

  // 팝업창 닫기
  @action
  closePostCode = async (event) => {
    this.isPopupOpen = false;
  }

  updateStore = async (event) => {
    let _this = this;

    this.props.updateStore(this.store, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        alert('수정되었습니다.');
        //_this.props.history.push('/reviewWriteConfig');
        //_this.isEditPassword = false;
      }

    });
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.updateStore(event)
    ///????
    //this.withdrawal = this.withdrawal.bind(this);
  };

  delTeamUser = async (id, password) => {
    let _this = this;
    let confirm = window.confirm('삭제하시면 해당 회원은 탈퇴처리 됩니다. 진행하시겠습니까?');
    if(confirm) {
      let delTeamUser = {
        id : id,
        password: password,
      }
      this.props.delTeamUser(delTeamUser, function() {
        _this.getTeamUserList();
      });
    }
  };

  applyDelStoreAccount = async () => {
    let _this = this;
    let useExpiryDate = moment(this.store.useExpiryDate).format('YYYY년 MM월 DD일');
    let useExpiryDateDiff = Math.abs(this.store.useExpiryDateDiffNow);

    let confirm1;
    if(!this.store.useExpiryDate) {
      confirm1 = window.confirm('현재 계정은 매달 10회 자동 답글 작성 혜택을 받고 계십니다. 그래도 서비스를 탈퇴를 신청하시겠습니까?');
    }
    else {
      confirm1 = window.confirm(useExpiryDate + '까지 '+useExpiryDateDiff+'일 동안 정상적으로 사용이 가능합니다. 그래도 서비스를 탈퇴를 신청하시겠습니까?');
    }
    if(confirm1) {
      let confirm2;
      confirm2 = window.prompt("탈퇴를 원하시면 이메일 "+this.store.email+" 을 하단에 입력해 주세요.");
      if(confirm2 === this.store.email) {
        let applyDelStore = {
          id : this.store._id,
          teamUser_id: this.teamUser._id,
        }
        // console.log(applyDelStore)
        this.props.applyDelStoreAccount(applyDelStore, function() {
          _this.store.isApplyDelStoreAccount = true;
          toast("탈퇴신청이 완료 되었습니다. 남은 서비스 기간 동안 한 번 더 고민해보시고, 우리 서비스를 계속 이용해 주실 것을 부탁드립니다.");
        });

      }
      else {
        toast("탈퇴신청이 취소 되었습니다. 이메일을 정확하게 입력해 주세요.");
      }
    }
    else {
      toast("탈퇴 신청을 취소해 주셔서 감사합니다. 고객님의 신뢰에 부응할 수 있도록 더 좋은 서비스 제공을 위해 최선을 다하겠습니다. 앞으로도 언제든지 의견이나 건의사항이 있으시면 말씀해 주세요. 여러분의 소중한 피드백이 저희 서비스를 더욱 발전시키는 데 큰 도움이 됩니다.");
    }
  };

  reUseAccount = async () => {
    let _this = this;
    let applyDelStore = {
      id : this.store._id,
      teamUser_id: this.teamUser._id,
      isRecover: true
    }
    this.props.applyDelStoreAccount(applyDelStore, function() {
      _this.store.isApplyDelStoreAccount = false;
      toast("서비스를 다시 이용해 주셔서 감사합니다.");
    });
  };

  render() {


    return (
      <div className="main-content">
        <PageTitle />

        {/*{this.boardList && this.boardList.docs && this.boardList.docs.length > 0 ?*/}
        {/*<div className="section-box basic-info-table">*/}
        {/*  <div className="section-tit">*/}
        {/*    <h3>팀원 정보</h3>*/}
        {/*  </div>*/}
        {/*  <div className="section-box">*/}
        {/*    <table>*/}
        {/*      <tbody>*/}
        {/*      {this.boardList.docs.map((item, i) => (*/}
        {/*          <tr key={i}>*/}
        {/*            <td>{i+1}</td>*/}
        {/*            <td>{item.name}({item.emailId})</td>*/}
        {/*            <td>{item.email || item.emailId}</td>*/}
        {/*            <td>{item.tel}</td>*/}
        {/*            <td>{item.createdAt}</td>*/}
        {/*            {(this.permissions && this.permissions['isSuper']) && <td>{!item.isSuper && <span onClick={e => this.delTeamUser(item._id, item.password)}>삭제</span>}</td>}*/}
        {/*          </tr>*/}
        {/*        ))}*/}
        {/*      </tbody>*/}
        {/*    </table>*/}
        {/*  </div>*/}
        {/*</div> : null}*/}

        {( this.isLogin && this.teamUser.emailId ) ?
          <div className="basic-info-box">
            {(this.permissions && this.permissions['isSuper']) ?
              <form onSubmit={e => this.onSubmit(e)}>
                <div className="section-box basic-info-table">
                  <div className="section-tit">
                    <h3>쇼핑몰 정보</h3>
                  </div>
                  <table>
                    <tbody>
                    <tr>
                      <th>쇼핑몰 이름</th>
                      <td>
                        {(this.permissions && this.permissions['isSuper']) ?
                          <InputGroup>
                            <FormInput name="name" placeholder="발송될 문자 또는 리뷰서비스에 표시될 서비스/ 쇼핑몰 / 브랜드의 이름입니다." disabled='disabled' value={this.store.name} onChange={event => {this.onChangeValue(event)}} className="table-input-text" />
                          </InputGroup> : <p>{this.store.name}</p>
                        }
                      </td>
                    </tr>
                    <tr className="info-id">
                      <th>쇼핑몰 도메인</th>
                      <td>
                        {(this.permissions && this.permissions['isSuper']) ?
                          <InputGroup>
                            <FormInput name="domain" placeholder="홈페이지 주소를 입력해 주세요." value={this.store.domain} onChange={event => {this.onChangeValue(event)}} className="table-input-text" />
                          </InputGroup> : <p>{this.store.domain}</p>
                        }
                      </td>
                    </tr>
                    {/*<tr className="info-id">*/}
                    {/*  <th>플랫폼</th>*/}
                    {/*  <td>*/}
                    {/*    <FormSelect name="platform" value={this.store.platform} className="search-type" disabled >*/}
                    {/*      <option value="cafe24">카페24</option>*/}
                    {/*      <option value="nhn_godo">NHN커머스 고도</option>*/}
                    {/*    </FormSelect>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*  <th>로고</th>*/}
                    {/*  <td>*/}
                    {/*    <div className="logo-upload">*/}
                    {/*      <>*/}
                    {/*        <img src={this.store.logo ? this.store.logo : noImg} alt={this.store.name}*/}
                    {/*             style={{width: '120px', height: '80px', border: '1px solid #CED4DA'}}/>*/}
                    {/*      </>*/}
                    {/*      <button className="btn-table btn-img-upload">다시 업로드</button>*/}
                    {/*    </div>*/}
                    {/*    <p className="guide-text">150KB 이하, 120px * 60px 권장</p>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    <tr className="info-id">
                      <th rowSpan="2">주소</th>
                      <td>
                        {(this.permissions && this.permissions['isSuper']) ?
                          <InputGroup>
                            <FormInput name="addrMain" placeholder="검색을 눌러 주소를 입력해 주세요." disabled='disabled' value={this.store.addrMain} onChange={event => {this.onChangeValue(event)}} className="table-input-text" />
                            <InputGroupAddon type="append"><Button theme="secondary" onClick={e => {this.openPostCode(e)}} ><i className="material-icons">검색</i></Button></InputGroupAddon>
                          </InputGroup> : <p>{this.store.addrMain}</p>
                        }
                      </td>
                    </tr>
                    <tr className="info-id">
                      <td>
                        {(this.permissions && this.permissions['isSuper']) ?
                          <InputGroup>
                            <FormInput name="addrSub" placeholder="나머지 주소를 입력해 주세요." value={this.store.addrSub} onChange={event => {this.onChangeValue(event)}} className="table-input-text" />
                          </InputGroup> : <p>{this.store.addrSub}</p>
                        }
                      </td>
                    </tr>
                    <tr className="info-id">
                      <th>회사 전화번호</th>
                      <td>
                        {(this.permissions && this.permissions['isSuper']) ?
                          <InputGroup>
                            <FormInput name="tel" placeholder="회사 전화번호를 입력해 주세요." value={this.store.tel} onChange={event => {this.onChangeValue(event)}} className="table-input-text" />
                          </InputGroup> : <p>{this.store.tel}</p>
                        }
                      </td>
                    </tr>
                    <tr className="info-id">
                      <th>회사 대표 이메일</th>
                      <td>
                        {(this.permissions && this.permissions['isSuper']) ?
                          <InputGroup>
                            <FormInput name="notificationEmail" placeholder="공지나 전달사항을 받을 대표 이메일을 입력해 주세요." value={this.store.notificationEmail} onChange={event => {this.onChangeValue(event)}} className="table-input-text" />
                          </InputGroup> : <p>{this.store.notificationEmail}</p>
                        }
                      </td>
                    </tr>
                    {/*<tr className="info-id">*/}
                    {/*  <th>시크릿 코드</th>*/}
                    {/*  <td><p>{this.store.clientSecret}</p></td>*/}
                    {/*</tr>*/}
                    {/*<tr className="info-id">*/}
                    {/*  <th>쇼핑몰(회사) 소개</th>*/}
                    {/*  <td>*/}
                    {/*    <textarea name="about" type="text" className="table-input-text" value={this.store.about || ''}*/}
                    {/*              onChange={e => this.onChangeValue(e)}></textarea>*/}
                    {/*  </td>*/}
                    {/*</tr>*/}
                    </tbody>
                  </table>
                </div>

                <div className="btn-save">
                  <input type="submit" value="저장" className="save-button"/>
                </div>
              </form>
              :
              <div className="section-box basic-info-table">
                <div className="section-tit">
                  <h3>쇼핑몰 정보</h3>
                </div>
                <table>
                  <tbody>
                  <tr>
                    <th>회사명(쇼핑몰)</th>
                    <td>{this.store.name}</td>
                  </tr>
                  <tr className="info-id">
                    <th>쇼핑몰 도메인</th>
                    <td>{this.store.domain}</td>
                  </tr>
                  <tr>
                    <th>로고</th>
                    <td>
                      <div className="logo-upload">
                        <>
                          <img src={this.store.logoImg ? this.store.logoImg : noImg} alt={this.store.name} style={{width: '120px', height: '80px', border: '1px solid #CED4DA'}}/>
                        </>
                      </div>
                    </td>
                  </tr>
                  {/*<tr className="info-id">*/}
                  {/*  <th>주소</th>*/}
                  {/*  <td>{this.store.addrMain} {this.store.addrSub}</td>*/}
                  {/*</tr>*/}

                  <tr className="info-id">
                    <th>회사 전화번호</th>
                    <td>{this.store.tel}</td>
                  </tr>
                  <tr className="info-id">
                    <th>회사 대표 이메일</th>
                    <td>{this.store.email}</td>
                  </tr>
                  {/*<tr className="info-id">*/}
                  {/*  <th>쇼핑몰(회사) 소개</th>*/}
                  {/*  <td><pre>{this.store.about}</pre></td>*/}
                  {/*</tr>*/}

                  </tbody>
                </table>
              </div>

            }
            {this.store.isApplyDelStoreAccount ?
                <p className="delete-account"><span onClick={e => this.reUseAccount()}>서비스 다시 이용하기</span></p> :
                <p className="delete-account"><span onClick={e => this.applyDelStoreAccount()}>회원탈퇴</span></p>
            }
              </div>
              :
              <p className='empty'>편집할 질문지 템플릿을 선택하시거나 '새로만들기' 버튼을 클릭하세요.</p>
            }

            {this.isPopupOpen ? (
                <ModalAddrPost onClose={this.closePostCode} setAddress={this.setAddress} isPopupOpen={this.isPopupOpen}  />
        ) : null}


      </div>
    )
  }
}

export default CompanyInfoEdit;
