import React, {Component, useState} from 'react';
import { decorate, observable, action, toJS, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { ColorRing } from  'react-loader-spinner'
import _ from "underscore";
import { srcToWebP } from 'webp-converter-browser'
import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import './style.scss'

import UploaderBanner from "../../../components/common/UploaderBanner";
import Uploader from "../../../components/common/Uploader";
import PageTitle from "../../../components/common/PageTitle";
import ModalLoading from "../../../components/common/ModalLoading";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FeatherIcon from 'feather-icons-react';
import {Modal, ModalBody, ModalHeader, Button, Col, Row} from "shards-react";
import moment from "moment";
import Calendar from "react-calendar";
import DateTimePicker from 'react-datetime-picker';
import axios from "axios";


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

import slide_demo from "../../../images/avatars/0.jpg";


let imgOptimizationType = '';//webp, canvas
let imgOptimizationQuality = 0.8;//webp, canvas


// @inject(({ common }) => ({ getUploadUrl: common.getUploadUrl, uploadImg: common.uploadImg, removeMedia: common.removeMedia, getDoc: common.getDoc }))
//
// @inject(({ product }) => ({ getProductList: product.getProductList, setProductSync: product.setProductSync }))
// @inject(({ review }) => ({ updateReview:review.updateReview }))


@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ common }) => ({ getUploadUrl: common.getUploadUrl, uploadImg: common.uploadImg, removeMedia: common.removeMedia, base64UploadImages: common.base64UploadImages, uploadImages: common.uploadImages,  cafe24UploadImages: common.cafe24UploadImages}))

@observer
class Banner extends Component {

    //basic
    @observable store = {};
    @observable teamUser = {};
    @observable permissions = {}
    @observable company = {};
    @observable isLogin = false;
    @observable isLoading = true;
    @observable urlParams = new URLSearchParams(window.location.search);

    //list
    @observable listParams = {
        page:1, limit:20,
    }
    @observable searchParams = {
        keyword: ''
    };
    @observable list = [];

    //tab
    @observable tabIndex = parseInt(Storage.get('tabIndex')) || 1;

    //banner
    @observable bannerList = {
        docs: [
            {imgUrl : slide_demo, linkUrl : slide_demo},
        ],
    };


    //page
    @observable uploadFiles = [];
    @observable bannerImgs = [];
    @observable viewMode = 'bannerEditor';
    @observable selectedBanner = {}

    @observable isfilerobotInit = false;

    //modals
    @observable modals = {
        isUploader: false,
    };
    

    constructor(props) {
        super(props);
        this.init()
    }



    @action
    init = async () => {
        if(this.props.isLogin) {
            let _this = this;
            //basic
            this.isLogin = this.props.isLogin;
            this.teamUser = this.props.me;
            this.store = this.props.me.store ? this.props.me.store : {};
            this.permissions = this.props.me.permissions;

            //todo
            // this.selectedBanner.imgUrl = this.bannerList.docs[0].imgUrl;
            // this.isfilerobotInit = false;
        }

    };


    modalToggle = function(target, data) {
        let _this = this;
        if(!target) {
            for( var i in this.modals) {
                if(i.indexOf('is') === 0) {
                    this.modals[i] = false;
                }
            }
        }
        else {
            this.modals[target] = !this.modals[target];
        }
    };

    getFiles = (files) => {
        this.uploadFiles = files;
        console.log(this.uploadFiles)
    }

    getPreviewFiles = (preview) => {
        for(var i in preview) {
            console.log(preview[i])
            this.bannerList.docs.push({imgUrl: preview[i].preview, linkUrl: preview[i].preview});
        }

    }

    getMediaType = (file) => {
        let type;
        if(file.type.indexOf('image/') > -1) {
            type = 'image';
        }
        else if(file.type.indexOf('video/') > -1) {
            type = 'video';
        }
        return type;
    };

    //todo 최대 5개까지만 업로드 가능하게 수정
    onFileUpload = async () => {
        let _this = this;

        let filesChunk = [];
        let files = toJS(this.uploadFiles);
        for (let i = 0; i < files.length; i++) {
            filesChunk.push(files[i]);

            // 5개씩 묶었거나 마지막 파일에 도달했을 경우
            if ((i + 1) % 5 === 0 || i === files.length - 1) {
                const formData = new FormData();
                formData.append("target", "temp");
                filesChunk.forEach(file => {
                    formData.append("file", file);
                });

                try {
                    const res = await this.props.uploadImages(formData);
                    let toUploadParams = {
                        store_id: _this.store._id,
                        imagesUrls: res.map(item => "/" + item.path) // 경로 변환 및 null 체크
                    };

                    const resCafe24 = await this.props.cafe24UploadImages(toUploadParams);
                    if(resCafe24 && resCafe24.images.length > 0) {
                        _this.bannerImgs = [..._this.bannerImgs, ...resCafe24.images].filter(item => item); // null 체크 추가
                    }

                } catch (error) {
                    console.error("Error uploading images: ", error);
                }

                filesChunk = []; // 파일 청크 초기화
            }
        }
    }

    onClickBanner = async (item) => {
        this.isfilerobotInit = false;
        this.selectedBanner = item;
        //this.isfilerobotInit = true;
    }

    toggleTab = async (tabIndex) => {
        this.tabIndex = tabIndex;
        Storage.set('tabIndex', tabIndex);
    }



    render() {
        let _this = this;

        return (
            <div className="main-content">
                <div className="banner-maker">
                    <div className="editor-wrap">
                        <div className="side-area">
                            <div className="tabs-wrap">
                                <div className={"tab" + (this.tabIndex === 1 ? ' active' : '' )} onClick={e => this.toggleTab(1)}>미디어</div>
                                <div className={"tab" + (this.tabIndex === 2 ? ' active' : '' )} onClick={e => this.toggleTab(2)}>디자인</div>
                            </div>
                            <div className="tab-cont-box">
                                {this.tabIndex === 1 ? (
                                    <div className="tab-cont banner-list">
                                        <h3>자주쓰는</h3>
                                        <ul>
                                            <li>슬라이드-1</li>
                                            <li>슬라이드-2</li>
                                            <li>배너1</li>
                                            <li>배너2</li>
                                            <li>배너3</li>
                                        </ul>
                                        <h3>슬라이드</h3>
                                        <ul>
                                            <li>슬라이드1</li>
                                            <li>슬라이드1</li>
                                            <li>슬라이드1</li>
                                            <li>슬라이드1</li>
                                            <li>슬라이드1</li>
                                        </ul>
                                        <h3>배너</h3>
                                        <ul>
                                            <li>디자인1</li>
                                            <li>디자인2</li>
                                            <li>디자인3</li>
                                            <li>디자인4</li>
                                            <li>디자인5</li>
                                        </ul>
                                        <h3>기타</h3>
                                        <ul>
                                            <li>디자인1</li>
                                            <li>디자인2</li>
                                            <li>디자인3</li>
                                            <li>디자인4</li>
                                            <li>디자인5</li>
                                        </ul>
                                        {/*<div>*/}
                                        {/*    <Button size="sm" onClick={e => this.modalToggle('isUploader')}><FeatherIcon*/}
                                        {/*        icon="upload-cloud" className="nd-icon"/>&nbsp;&nbsp;&nbsp;&nbsp;배너*/}
                                        {/*        등록</Button>*/}
                                        {/*</div>*/}
                                    </div>
                                ) : null}
                                {this.tabIndex === 2 ? (
                                    <div className="tab-cont">


                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="main-area">
                            <div className="viewer-box">
                                <div className="btn-wrap">
                                    {this.viewMode === 'preview' ? (
                                        <>
                                            <Button onClick={e => this.viewMode = 'bannerEditor'}>이미지 회원</Button>
                                            <Button onClick={e => this.viewMode = 'bannerEditor'}>배너 편집</Button>
                                            <Button onClick={e => this.viewMode = 'bannerEditor'}>배너 편집</Button>
                                            <Button onClick={e => this.viewMode = 'bannerEditor'}>배너 편집</Button>
                                        </>) : null}
                                    {this.viewMode === 'bannerEditor' ? (
                                        <Button onClick={e => this.viewMode = 'preview'}>미리보기</Button>) : null}

                                </div>
                                {this.viewMode === 'bannerEditor' ? (
                                    <div className="banner-editor">
                                        {this.selectedBanner.imgUrl && (
                                            //https://github.com/scaleflex/filerobot-image-editor
                                            //https://www.npmjs.com/package/react-konva/v/17.0.2-6
                                            <FilerobotImageEditor Editor
                                                className="filerobotImageEditor"
                                                //show={this.isfilerobotInit}
                                                onClose={!this.isfilerobotInit}
                                                source={this.selectedBanner.imgUrl}
                                                onSave={(editedImageObject, designState) =>
                                                    console.log('saved', editedImageObject, designState)
                                                }
                                                // Crop={{
                                                //     presetsItems: [
                                                //         {
                                                //             titleKey: '1*1',
                                                //             descriptionKey: '4:3',
                                                //             ratio: 4 / 3,
                                                //             // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                                                //         },
                                                //         {
                                                //             titleKey: '1*2',
                                                //             descriptionKey: '21:9',
                                                //             ratio: 21 / 9,
                                                //             // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                                                //         },
                                                //         {
                                                //             titleKey: '1*3',
                                                //             descriptionKey: '21:9',
                                                //             ratio: 21 / 9,
                                                //             // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                                                //         },
                                                //     ],
                                                //     presetsFolders: [
                                                //         {
                                                //             titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
                                                //             // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                                                //             groups: [
                                                //                 {
                                                //                     titleKey: 'facebook',
                                                //                     items: [
                                                //                         {
                                                //                             titleKey: 'profile',
                                                //                             width: 180,
                                                //                             height: 180,
                                                //                             descriptionKey: 'fbProfileSize',
                                                //                         },
                                                //                         {
                                                //                             titleKey: 'coverPhoto',
                                                //                             width: 820,
                                                //                             height: 312,
                                                //                             descriptionKey: 'fbCoverPhotoSize',
                                                //                         },
                                                //                     ],
                                                //                 },
                                                //             ],
                                                //         },
                                                //     ],
                                                // }}
                                                // config={{
                                                //     tools: ['adjust', 'effects', 'filters', 'rotate'],
                                                //     initialZoom: 1, // 이미지를 원본 크기로 로드
                                                //     noNewSizeOnResize: true // 창 크기 조정 시 이미지 크기를 변경하지 않음
                                                // }}

                                                initialZoom={1}
                                                noNewSizeOnResize={true}
                                                previewPixelRatio={1}
                                                savingPixelRatio={1}
                                                //tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
                                                tabsIds={[TABS.RESIZE, TABS.ADJUST, TABS.FINETUNE, TABS.FILTERS, TABS.FILTERS, TABS.ANNOTATE]} // or {['Adjust', 'Annotate', 'Watermark']}
                                                defaultTabId={TABS.RESIZE} // or 'Annotate'
                                                // defaultToolId={TOOLS.TEXT} // or 'Text'
                                            />
                                        )}
                                    </div>
                                ) : null}
                                {this.viewMode === 'preview' ? (
                                    <div>
                                        <Swiper className="media-list"
                                                spaceBetween={50}
                                                slidesPerView={3}
                                                onSlideChange={() => console.log('slide change')}
                                                onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            {Object.keys(this.bannerImgs).map((key, i) => {
                                                    return <SwiperSlide key={i} className="banner-con"><img
                                                        src={this.bannerImgs[key].path}/></SwiperSlide>
                                                }
                                            )}
                                        </Swiper>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="toggle-side-area">
                            <div>
                                <Row>
                                    <Col md="12">단순배너 / 슬라이드 배너</Col>
                                    <Col md="12">배너 크기(가로, 세로)</Col>
                                    <Col md="12">행 당 배너 수</Col>
                                    <Col md="12">행 수</Col>
                                    <Col md="12">간격</Col>{/* 슬라이드 */}
                                    <Col md="12">디자인(이미지 선택)</Col>{/*단순배너와 슬라이드 배너의 디자인은 다를수 있음*/}
                                    <Col md="12">슬라이드 이동 배너 수</Col>{/* 슬라이드 */}
                                    <Col md="12">중앙 슬라이드</Col>{/* 슬라이드 */}
                                    <Col md="12">초기 슬라이드</Col>{/* 슬라이드 */}
                                    <Col md="12">루프 모드(disabled, loop, rewind)</Col>{/* 슬라이드 */}

                                    <Col md="12">simulate touch, allow touch move, follow
                                        finger, </Col>{/* 슬라이드 */}
                                    <Col md="12">watch sliders progress</Col>{/* 슬라이드 */}

                                    <Col md="12">효과(slide, fade, cube, flip, coverflow, cards, panorama,
                                        carousel,
                                        shutters, slicer, gl, thinder, material, creative)</Col>{/* 슬라이드 */}
                                    <Col md="12">슬라이드 속도(slide, fade, cube, flip, coverflow, cards,
                                        panorama,
                                        carousel,
                                        shutters, slicer, gl, thinder, material, creative)</Col>{/* 슬라이드 */}

                                    <Col md="12">좌우 버튼(컬러, 위치(inside, outside / top, middle, bottom) / hide
                                        on
                                        click)</Col>{/* 슬라이드 */}
                                    <Col md="12">페이징 표시(컬러, 위치(top, bottom) / hide on click / type(bullets,
                                        progressbar,
                                        fration) / progressbar(상단, 왼쪽))</Col>{/* 슬라이드 */}

                                    <Col md="12">자동 슬라이드</Col>{/* 슬라이드 */}
                                    <Col md="12">delay</Col>{/* 슬라이드 */}
                                    <Col md="12">pause on pointer enter</Col>{/* 슬라이드 */}
                                    <Col md="12">disable on interaction</Col>{/* 슬라이드 */}
                                    <Col md="12">reverse direction</Col>{/* 슬라이드 */}
                                    <Col md="12">stop on last slide</Col>{/* 슬라이드 */}

                                    <Col md="12">스크롤바(기본 컬러, 바 컬러, 위치(상단, 하단), draggable, hide after
                                        interaction,
                                        snap
                                        on release )</Col>{/* 슬라이드 */}
                                    <Col md="12">free mode(sticty, momentum, momentum bounce, momentum
                                        bounce
                                        ration,
                                        momentum ratio, momentum velocity ratio )</Col>{/* 슬라이드 */}

                                    <Col md="12">키보드 컨트롤</Col>{/* 슬라이드 */}
                                    <Col md="12">마우스 훨 컨트롤</Col>{/* 슬라이드 */}

                                    <Col md="12">paralax</Col>{/* 슬라이드 */}
                                    <Col md="12">zoom</Col>{/* 슬라이드 */}
                                </Row>
                                <ul>
                                    {this.bannerList && this.bannerList.docs && this.bannerList.docs.length > 0 ?
                                        this.bannerList.docs.map((item, i) => (
                                            <li key={i} onClick={e => this.onClickBanner(item)}>
                                                <span className="page-num">{i + 1}</span>
                                                <div className="media"><img src={item.imgUrl} alt=""/></div>
                                            </li>
                                        )) : null}
                                </ul>
                                <div className="uploder-wrap"><UploaderBanner getFiles={this.getFiles}
                                                                              getPreviewFiles={this.getPreviewFiles}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* todo https://uiinitiative.com/catalog */}

                {/* modal */}
                <Modal size="lg" className='modal-review-write-form' open={this.modals.isUploader}
                       toggle={this.modalToggle.bind(this)} centered={true}>
                    <ModalHeader>리뷰 작성</ModalHeader>
                    <ModalBody className="history-window-popup">
                        <div className="history-popup-contents">
                            <div><Uploader getFiles={this.getFiles}/></div>
                            <div className="text-center">
                                <Button onClick={event => this.onFileUpload()}>추가하기</Button>
                                <Button className="ml-2" theme="light"
                                        onClick={event => this.modals.isUploader = false}>닫기</Button>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

export default Banner;
