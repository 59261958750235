import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";
import { Redirect } from "react-router-dom"
import history from "../utils/history";


export class Widget {


  @action getWidgetList = (data, cb) => {

    let property = ['store_id', 'mode', 'limit', 'page', 'sort', 'searchParams', 'selecting'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getWidgetList',
      variables: variables,
      getData: 'docs, totalDocs, limit, page, nextPage, prevPage, totalPages, pagingCounter, meta'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
      .then(function (res) {
        if(res.status === 200) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }
        }

      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  @action getWidgetInReviewList = (data, cb) => {

    let property = ['widget_id'];
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'query',
      operation: 'getWidgetInReviewList',
      variables: variables,
      getData: 'data'
    };

    const gpqParams = gql(params);

    return axios.post('/', gpqParams)
        .then(function (res) {
          if(res.status === 200) {
            if(typeof cb === 'function') {
              cb(res.data.data[params.operation]);
            }
            else {
              return res.data.data[params.operation];
            }
          }

        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
  };


  @action updateWidget = (data, cb) => {
    if(data._id) data.id = data._id;
    let property = ['id', 'store_id', 'title', 'isTitleDis', 'type', 'selecting', 'displayArea', 'row', 'col', 'isAutoPlay', 'dataPer', 'isDisplay', 'head', 'style']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateWidget',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };


  @action updateWidgetInReview = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateWidgetInReview',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action updateWidgetInReviewOrder = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'updateWidgetInReviewOrder',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action removeWidgetInReview = (data, cb) => {

    let params = {
      type: 'mutation',
      operation: 'removeWidgetInReview',
      variables: data,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
        .then(function (res) {
          if(typeof cb === 'function') {
            cb(res.data.data[params.operation]);
          }
          else {
            return res.data.data[params.operation];
          }

        })
        .catch(function (error) {
          if(typeof cb === 'function') {
            cb(error)
          }
          return error;
        });
  };

  @action removeWidget = (data, cb) => {
    if(data._id) data.id = data._id;
    let property = ['id', 'store_id']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'removeWidget',
      variables: variables,
      getData: 'data'
    };
    const gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        if(typeof cb === 'function') {
          cb(res.data.data[params.operation]);
        }
        else {
          return res.data.data[params.operation];
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });
  };

}
