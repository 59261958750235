
exports.TermsAndPrivacy = {
    privacyPolicy: "<div id=\"privacy\">" +
        "                  <div class=\"mg-bottom-56px\">" +
        "                    <h2>개인정보처리방침 (2024.02.05)<strong></strong><br></h2>" +
        "                  <p class=\"paragraph-4\"><strong>공지 일자: 2024년 02월 05일<br>" +
        "적용 일자: 2024년 02월 05일<br><br>" +
        "                    <h3>(주)온노마드 개인정보처리방침</h3>" +
        "                  </div>" +
        "                  <p class=\"paragraph-4\">㈜온노마드('㈜온노마드' 이하 “회사”, '온리플’ 라 칭함)는 통신비밀 보호법, 전기통신사업법, 개인정보보호법, 정보 통신망 이용촉진 및 정보보호에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하며, 관련 법령에 따른 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.<br>" +
        "개인정보 처리방침은 개인정보와 관련한 법령 또는 지침의 변경이 있는 경우, “회사”의 정책이 변동되는 경우 변경될 수 있으나 변경된 내용에 대해서는 “회원” 여러분에게 지체없이 통지하고 수시로 확인할 수 있도록 <a target='_blank' href=\"https://ocadmin.onreview.co.kr/privacy\">개인정보처리방침 변경 내역 페이지</a>에 공지하여 게시하겠습니다.<br>" +
        "<strong></strong><br>“회사”의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.<br>" +
        "1. 수집하는 개인정보의 항목 및 수집방법<br>" +
        "2. 개인정보의 수집 및 이용목적<br>" +
        "3. 개인정보의 제 3자 제공<br>" +
        "4. 개인정보의 처리 위탁<br>" +
        "5. 개인정보의 보유 및 이용 기간<br>" +
        "6. 개인정보 파기절차 및 방법<br>" +
        "7. 이용자 및 법정대리인의 권리와 그 행사방법<br>" +
        "8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br>" +
        "9. 개인정보의 안전성 확보 조치<br>" +
        "10. 개인정보 보호책임자와 담당자의 연락처<br>" +
        "11. 변경 내용의 고지와 동의 요청<br><br>" +
        "1. 수집하는 개인정보의 항목 및 수집방법<br><br>" +
        "가. 수집하는 개인정보의 항목</strong><br>" +
        "광고주 정보: 쇼핑몰 관리 목적 계정의 아이디 및 비밀번호<br>" +
        "쇼핑몰 고객정보: 고객명, 고객ID, email 주소, 휴대전화번호, 구매정보(일시, 상품명, 배송정보, 등급)<br>" +
        "결제카드 정보 : 카드사, 카드종류, 카드번호, 유효기간, 사업자번호, 생년월일, 암호화된 카드 식별정보(Key)<br><br>" +
        "나. 수집방법</strong><br>" +
        "온리플는 홈페이지(onreview.co.kr) 웹 문서를 통한 사용자 입력 방법으로 개인정보를 수집하며, 경우에 따라 출력 된 형태의 종이문서 혹은 이메일 혹은 메신저 챗봇 등을 통하여 일부 정보를 수집할 수도 있습니다. 또한, 광고주가 제공하는 쇼핑몰 고객정보는 쇼핑몰 어드민 및 API 등을 통해 수집하며 광고주가 제공한 방법을 통해서만 수집됩니다.<br><br>" +
        "2. 개인정보의 수집 및 이용 목적<br></strong><br>" +
        "온리플는 광고주의 쇼핑몰에 카카오알림톡 위탁발송, 고객상품 후기 위탁관리(리뷰 작성, 관리, 혜택지급관리 등), 리뷰 위젯 관리, 리뷰 생성 촉진관리, 리뷰 데이터 분석, 콘텐츠 마켓 등 제반 업무를 위하여 개인정보를 수집 및 이용합니다.<br><br>" +
        "3. 개인정보의 제3자 제공</strong><br>온리플는 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우 외에는 개인정보를 제3자에게 제공하지 않습니다.<br><br>" +
        "4. 개인정보처리 위탁</strong><br>" +
        "온리플는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따른 위탁계약 시 개인정보 가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 온리플의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.<br><br>" +
        "4-1. 국내서비스 기반 인프라 운영<br><br>" +
        "호스팅 서버 서비스<br>" +
        "가. 위탁받는 자(수탁자): Amazon Web Services, Inc (한국)<br>" +
        "나. 위탁하는 업무의 내용: 개인정보가 포함된 처리 인프라 운영<br>" +
        "다. 보유 기간 : 이용 목적 달성 시 까지<br><br>" +
        "결제 서비스<br>" +
        "가. 위탁받는 자(수탁자): (주)KG모빌리언스<br>" +
        "나. 위탁하는 업무의 내용: 서비스 이용료 결제<br>" +
        "다. 보유 기간 : “회원” 탈퇴 또는 관계 법령이 정하는 기간 또는 위탁 계약 종료 시까지<br><br>" +
        "4-2. 개인정보 처리 국외서비스 기반 인프라 운영<br><br>" +
        "업체명 : MongoDB, Inc<br>" +
        "정보보호 관리 책임자 연락처 : MongoDB, Inc<br>" +
        "이전되는 국가: 미국<br>" +
        "이전일시 및 방법: 가입 시 및 콘텐츠 생성 시 입력한 정보를 네트워크를 통해 이전<br>" +
        "이전되는 개인정보 항목: 서비스 이용 기록 또는 수집된 개인정보<br>" +
        "이전 목적: 서비스 운영 목적<br>" +
        "보유 및 이용 기간 : 이용 목적 달성 시 까지<br><br>" +


        "5. 개인정보의 보유 및 이용 기간</strong><br>" +
        "“회사”는 서비스를 제공하는데 필요한 최소한의 범위 내에서 이용자의 개인정보를 수집하며, 수집하는 정보는 이용자의 동의를 받은 범위 내에서만 사용합니다. “회사”는 그 외 사전 동의 없이 이용자의 개인정보를 동의를 받은 범위를 초과하여 이용하거나 외부에 공개하지 않습니다. “회사”는 제공하는 서비스에 따라 다음과 같이 이용자의 개인정보를 수집 및 이용,보유,파기하고 있습니다.<br><br>" +
        "가.<br>" +
        "보관하는 정보: 후기 정보 중 고객 개인정보(작성자의 ID, EMAIL 등)<br>" +
        "보존이유: 광고주의 상품 후기 게시판 유지 목적<br>" +
        "보존기간: 광고주와의 계약 종료 시까지<br><br>" +
        "나.<br>" +
        "보관하는 정보: 쇼핑몰 고객의 주문 관련 정보<br>" +
        "보존이유: 계약 또는 청약철회 등에 관한 기록 보존<br>" +
        "보존기간: 동영상 리뷰 생성 촉진 시스템 사용 시 5년이 지나면 자동으로 파기<br>" +
        "보존근거: 전자상거래 등에서의 소비자보호에 관한 법류에 의해 보존 5년, 그 외 모든 개인정보는 목적 달성 후 지체없이 파기합니다.<br><br>" +
        "6. 개인정보의 파기</strong><br>" +
        "“회사”는 원칙적으로 수집한 개인정보에 대해 이용 목적이 달성된 후, 즉시 해당 개인정보를 파기하고 있습니다. 정보 주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성 되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<strong><br></strong><br>" +
        "가. 파기절차 이용자의 개인정보에 대해 개인정보의 수집·이용 목적이 달성된 후에는 보유기간 후 해당 정보를 지체 없이 파기합니다. 다만, 법령에 따라 일정 기간 저장된 후 파기될 수 있습니다. 전자적 파일 형태인 경우 복구 및 재생되지 않도록 기술적인 방법을 이용하여 완전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.<br>" +
        "나. 파기 방법<br>" +
        "전자적 파일 형태로 저장된 개인정보는 복원이 불가능한 방법으로 파기합니다.종이(인쇄물, 서면 등)에 출력된 개인정보는 분쇄기를 통한 분쇄 또는 소각을 통해 파기합니다.<br><br>" +
        "7. 이용자 및 법정대리인의 권리와 그 행사방법</strong><br>" +
        "“회원” 및 법정대리인은 언제든지 “회사”가 처리하고 있는 개인정보에 대하여 열람, 수정, 동의철회, 삭제 등을 요청할 수 있으며, 계정삭제를 요청할 수도 있습니다. 다만 개인정보 수집, 처리에 대한 동의 철회 또는 계정삭제시 서비스의 일부 또는 전체 이용이 제한될 수 있습니다.<br>" +
        "“회원”이 개인정보의 오류에 대한 정정을 요청할 경우, “회사”는 해당 개인정보의 오류를 정정하기 전까지 개인정보를 이용 또는 제공하지 않습니다. 또한, 잘못된 개인정보를 제 3 자에게 제공한 경우 “회원”의 요청으로 정정된 개인정보를 제 3 자에게 지체없이 통지하여 해당 개인정보의 정정이 이루어지도록 조치하겠습니다.<br>" +
        "“회사”는 “회원” 혹은 법정 대리인의 요청에 의해 서비스 이용계약 해지 또는 계정삭제를 요청받은 개인정보를 ' 개인정보의 보유 및 이용기간' 에 명시된 바에 따라 처리하고 그 외의 용도로 열람하거나 이용할 수 없도록 처리하고 있습니다.<br><br>" +
        "8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</strong><br>" +
        "“회사”는 “회사”의 홈페이지 방문자에게 적정한 서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러 오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는 데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내 하드디스크에 저장되기도 합니다.<br><br>" +
        "가. 쿠키의 사용 목적<br>" +
        "쿠키는 팝업 공지사항의 중복 공지 방지를 위해 사용됩니다.<br>" +
        "쿠키는 리뷰 작성 팝업의 방지를 위해 사용됩니다.<br><br>" +
        "나. 쿠키의 설치/운영 및 거부<br>" +
        "이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>" +
        "쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하 거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. - 설정방법 예(인터넷 익스플로러의 경우): 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보<br><br>" +
        "9. 개인정보의 안전성 확보 조치</strong><br>" +
        "온리플는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.<br>" +
        "가. 개인정보 처리 직원의 최소화<br>" +
        "개인정보를 처리하는 직원을 지정하고 접근권한을 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br>" +
        "나. 내부관리계획의 수립 및 시행<br>" +
        "개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>" +
        "온리플는 개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보보호 의무 등에 관해 정기적 인 사내 교육과 외부 교육을 시행하고 있습니다.<br>" +
        "개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.<br>" +
        "신규로 채용된 직원에게 정보보호서약서 또는 개인정보보호서약서에 서명하게 하여 직원에 의한 정보유출을 미 리 방지하고 있으며, 개인정보처리방침에 대한 이행사항과 직원의 준수 여부를 감사하기 위한 내부절차를 마련 하여 지속적으로 시행하고 있습니다.<br>" +
        "직원 퇴직 시 비밀유지서약서에 서명함으로 “회원”의 개인정보를 처리하였던 자가 직무상 알게 된 개인정보를 훼손·침해 또는 누설하지 않도록 하고 있습니다.<br>" +
        "그 밖에 내부 관리자의 실수나 기술관리 상의 사고로 고객님의 개인정보가 상실ㆍ유출ㆍ변조ㆍ훼손되면 온리플는 즉각 고객님께 해당 사실을 알리고 적절한 대책과 보상을 마련할 것입니다.<strong></strong><br>" +
        "다. 개인정보의 암호화<br>" +
        "이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.<strong></strong><br>" +
        "라. 개인정보에 대한 접근 제한<br>" +
        "개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br><br>" +
        "10. 개인정보 보호책임자</strong><br>" +
        "온리플는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해 구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>" +
        "개인정보 보호책임자<br>" +
        "성명 : 윤정진<br>" +
        "이메일 : <a href=\"mailto:admin@onnomads.com\">admin@onnomads.com<br></a>" +
        "개인정보 민원 처리 담당 부서<br>" +
        "부서 : 고객 지원<br>" +
        "이메일 : <a href=\"mailto:contract@onnomads.com\">admin@onnomads.com</a><strong><br></strong><br>" +
        "정보주체께서는 온리플의 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자와 담당 부서로 문의하실 수 있습니다. 온리플는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br><br>" +
        "11. 변경내용의 고지와 동의요청</strong><br>" +
        "본 개인정보 처리방침은 시행일로부터 적용됩니다. “회사”가 개인정보처리방침을 변경하는 경우에 는 변경 및 시행의 시기, 변경된 내용을 지속적으로 공개하며, 변경된 내용은 “회원”이 쉽게 확인할 수 있도록 변경 전∙후를 비교하여 최소 7일 전에 공개합니다.<br><br>" +
        "개인정보 처리방침 변경 내역<br>" +
        "<a href=\"https://ocadmin.onreview.co.kr/privacy\">이전 개인정보처리방침(2024.02.05)<br></a><br>" +
        "개인정보처리방침 공고 일자: 2024. 02. 05.<br>" +
        "개인정보처리방침 시행 일자: 2024. 02. 05.<br></p>" +
        "                </div>",

    termsOfService: "<div>" +
        "<h2>온리플 이용“약관”<strong></strong><br></h2>" +
        "<p><strong>공지 일자: 2024년 2월 6일<br>" +
        "적용 일자: 2024년 2월 6일</strong></p><br>" +

        "<strong>제 1 조 (목적)</strong><br>" +
        "본 “약관”(이하 “약관”)은 (주)온노마드(이하 “회사”)가 제공하는 “리뷰 관리 시스템 및 기타 모든 관련 서비스”(이하 “서비스”) 이용에 있어 “회사”와 이용 고객(이하 “회원”) 간의 권리, 의무 및 책임 사항 등 필요한 사항을 규정함으로써 양 당사자의 원활한 서비스 이용을 도모하는 것을 목적으로 합니다.<br><br>" +

        "<strong>제 2 조 (용어의 정의)</strong><br>" +
        "“약관”에서 사용하는 용어의 정의는 아래와 같습니다.<br>" +
        "① “리뷰 관리 시스템”이라 함은 “회원”이 설정한 기준에 따라 상품 리뷰 관리 시스템 및 리뷰 위젯을 생성함을 말합니다.<br>" +
        "② “회원”라 함은 서비스를 이용하기 위해 사업자등록증을 소지하거나 발급받을 자격이 있는 개인 또는 법인으로, “회사”에 필요한 정보를 제공하고 본 “약관”에 동의한 후 서비스 가입 절차를 완료한 이용자를 의미합니다.<br>" +
        "③ “아이디(ID)”라 함은 “회원”의 식별과 서비스 이용을 위해 “회원”이 생성하고 “회사”가 승인하는 고유한 문자, 숫자, 특수문자의 조합입니다. 이는 이메일 주소 형태일 수도 있습니다.<br>" +
        "④ “비밀번호(Password)”라 함은 “회원”의 아이디와 일치하는 “회원”임을 확인하고, “회원”의 계정 보호를 위해 “회원”이 설정한 고유한 문자 또는 숫자의 조합을 말합니다.<br>" +
        "⑤ “호스팅사”라 함은 “회원”의 쇼핑몰 운영을 지원하는 서비스를 제공하는 국내외 업체를 의미합니다. 이는 카페24 등과 같은 쇼핑몰 관리자 서비스를 운영하는 “회사”를 포함합니다.<br><br>" +

        "<strong>제 3 조 (“약관”의 게시와 개정)</strong><br>" +
        "① “회사”는 “약관”의 내용을 “회원”이 쉽게 확인할 수 있도록 서비스의 초기 화면 또는 연결 화면에 게시합니다.<br>" +
        "② “회사”는 법률을 위반하지 않는 범위 내에서 “약관”을 개정할 수 있는 권리를 보유합니다.<br> " +
        "③ “약관”에 변경이 있는 경우, “회사”는 변경 내용과 적용 예정일을 명시하여 적용 예정일 최소 7일 전부터 서비스 화면에 공지합니다. “회원”에게 불리한 변경의 경우에는 적용 예정일 최소 30일 전부터 공지합니다.<br> " +
        "④ 개정 “약관”이 공지된 후 “회원”이 적용 예정일 전일까지 거부 의사를 표시하지 않을 경우, “회원”은 개정된 “약관”에 동의한 것으로 간주합니다. “회사”는 이러한 점을 명확히 하기 위해 공지 시 이를 강조합니다.<br> " +
        "⑤ “회원”이 개정된 “약관”에 동의하지 않을 경우, 적용 예정일 전일까지 “회사”에 거부 의사를 표시할 권리가 있으며, 이 경우 “회원”은 탈퇴할 수 있습니다.<br><br>" +

        "<strong>제 4 조 (“약관”의 효력)</strong><br>" +
        "① 본 “약관”은 “회원”이 서비스 이용을 원하고 본 “약관”에 대해 '동의하기' 버튼을 클릭함으로써 효력이 발생합니다. 이 행위는 “회원”이 “약관”의 내용을 충분히 이해하고 그 적용에 동의함을 의미합니다." +
        "② “회사”는 “회원”이 “약관”을 쉽게 접근하고 인쇄할 수 있도록 필요한 기술적 조치를 취합니다.<br> " +
        "③ 본 “약관”은 “회원”이 “약관”에 동의한 날부터 “회원” 탈퇴 시까지 적용됩니다. 특정 조항은 “회원” 탈퇴 후에도 계속 적용될 수 있습니다.<br> " +
        "④ “약관”에 명시되지 않은 사항은 콘텐츠산업진흥법, 전자상거래에서의 소비자 보호에 관한 법률, “약관”의 규제에 관한 법률 등 관련 법령을 따릅니다.<br><br>" +

        "<strong>제 5 조 (“회원” 가입)</strong><br>" +
        "① 서비스를 이용하고자 하는 자(이하 \"가입신청자\")는 본 “약관”에 동의하고 “회사”가 정한 가입 절차에 따라 신청을 제출해야 합니다. “회사”는 가입신청자의 신청을 승낙함으로써 “회원” 가입이 완료됩니다." +
        "② “회사”는 가입신청자 또는 “회원”이 다음과 같은 사유에 해당하는 경우, 가입 신청의 승낙을 거부하거나, 이미 가입된 “회원”에 대해서는 “회원” 자격을 해지할 수 있습니다<br>" +
        "1. 이전에 “약관” 위반으로 “회원” 자격을 상실한 적이 있는 경우<br>" +
        "2. 서비스 이용 제한 상태에서 탈퇴 후 재가입을 신청하는 경우<br>" +
        "3. 실명이 아닌 명의나 타인의 명의를 사용하여 신청한 경우<br>" +
        "4. 가입 신청 시 허위 정보를 제공하거나, “회사”가 요구하는 정보를 제공하지 않은 경우<br>" +
        "5. 기타 가입신청자의 귀책사유로 인해 승낙이 불가능하거나, 본 “약관” 및 기타 “회사” 정책을 위반하여 신청하는 경우<br> " +
        "③ 가입신청자의 신청에 대해 필요 시 실명 확인 및 본인 인증을 요구할 수 있으며, 이는 전문 기관을 통해 이루어질 수 있습니다.<br> " +
        "④ “회원” 가입은 “회사”가 가입 신청을 승낙하고, 이를 가입신청자에게 통지한 시점에 성립됩니다.<br><br>" +

        "<strong>제 6 조 (“회원”계정 관리 의무)</strong><br>" +
        "① “회원”은 자신의 계정 아이디와 비밀번호 관리 책임을 집니다. 이 정보는 제3자에게 공개되거나 사용되도록 허용되지 않습니다.<br> " +
        "② “회원”은 자신의 아이디나 비밀번호가 도용되었거나 제3자에 의해 무단 사용되고 있는 것을 인지한 경우, 즉시 “회사”에 이를 통지하고, “회사”의 지시에 따라야 합니다.<br> " +
        "③ “회원” 정보에 변동이 있는 경우, “회원”은 “회사”의 웹사이트에 접속하여 직접 정보를 수정하거나, 고객센터를 통해 “회사”에 해당 변경 사항을 알려야 합니다.<br> " +
        "④ 사업자등록번호로 가입한 “회원”이 휴업, 폐업 또는 기타 상태 변경 시, “회사”가 정한 절차에 따라 “회사”에 정보 수정을 요청해야 합니다.<br> " +
        "⑤ “회원”이 위 조항을 준수하지 않아 발생하는 불이익에 대해 “회사”는 책임지지 않습니다.<br><br> " +

        "<strong>제 7 조 (서비스 종류)</strong><br>" +
        "“회사”가 제공하는 서비스의 종류는 아래와 같습니다.<br>" +
        "가. 리뷰 관리 시스템<br>" +
        "나. 기타 모든 관련 서비스<br><br>" +

        "<strong>제 8 조 (서비스 이용)</strong><br>" +
        "① “회원”이 서비스에 대한 결제를 완료한 날로부터 서비스 이용이 시작됩니다. 무상으로 제공되는 서비스의 경우에도 이용 시작이 동일하게 적용됩니다.<br> " +
        "② “회사”는 업무상 또는 기술상의 장애가 발생하지 않는 한, 연중무휴로 하루 24시간 서비스를 제공합니다. 그러나 본 “약관”의 다른 조항(제14조 포함)에 따라 서비스 이용에 제한이 있을 수 있습니다.<br><br>" +

        "<strong>제 9 조 (서비스 이용 요금)</strong><br>" +
        "① “회사”는 제공하는 서비스를 유료 및 무료로 구분하여 운영합니다. 각 서비스의 세부 내용, 요금, 특성 및 제휴 마케팅에 따른 커미션 등은 개별 서비스 페이지에 명시적으로 안내합니다.<br> " +
        "② “회사”는 서비스의 지속적인 개선 및 유지 관리를 위해 필요한 경우 서비스 이용 요금을 변경할 수 있으며, 이러한 변경은 “회사”의 정책에 따라 결정됩니다. 요금 변경 시 “회사”는 변경 사항을 “회원”에게 사전 공지합니다.<br><br>" +

        "<strong>제 10 조 (서비스의 변경)</strong><br>" +
        "① “회사”는 서비스 제공의 품질 및 안정성 향상을 위해 서비스의 내용, 운영 방식 또는 기술적 사항을 변경할 수 있습니다.<br> " +
        "② 서비스의 중요 변경 사항이 발생하는 경우, “회사”는 변경 내용과 적용 예정일을 사전에 “회원”에게 공지합니다. “회원”의 권리나 의무에 중대한 영향을 미치는 변경의 경우에는 최소 7일 전, “회원”에게 불리한 변경의 경우에는 최소 30일 전에 해당 정보를 공지합니다.<br> " +
        "③ “회원”은 변경된 서비스 내용에 동의하지 않을 경우, 변경 적용일 이전에 “회사”에 거부 의사를 표시하고 “회원” 탈퇴를 선택할 수 있습니다.<br><br>" +

        "<strong>제 11 조 (서비스의 중단)</strong><br>" +
        "① “회사”는 다음과 같은 사유가 발생한 경우, 서비스 제공을 일시적으로 중단할 수 있습니다.<br> " +
        "1. 정보통신설비의 보수점검, 교체, 고장, 장애<br>" +
        "2. 통신두절<br>" +
        "3. 운영상 합리적인 사유(예: 시스템 업그레이드, 정기 점검 등)<br>" +
        "② 서비스 중단이 예정되어 있는 경우, “회사”는 사전에 “회원”에게 통지합니다. 단, 불가피한 사유로 사전 통지가 어려운 경우에는 사후에 통지할 수 있습니다. 이러한 통지는 제12조에 명시된 방법을 따릅니다.<br> " +
        "③ “회사”는 서비스 중단으로 인한 “회원”의 손해에 대해 고의 또는 과실이 없는 한 책임을 지지 않습니다. 이는 불가항력적인 사유로 인한 서비스 중단을 포함합니다.<br> " +
        "④ “회사”는 다음과 같은 상황에서 서비스의 전부 또는 일부 제공을 중지할 수 있습니다:<br>" +
        "1. 전기통신사업법에 따라 기간통신사업자가 전기통신서비스 제공을 중지하는 경우<br>" +
        "2. 국가비상사태, 정전, 서비스 설비의 장애, 서비스 이용 폭주 등으로 인해 서비스 제공이 어려운 경우<br>" +
        "3. 네트워크상의 안전을 위협하는 음해 행위가 발생한 경우<br>" +
        "4. “호스팅사”의 서비스 중단 등으로 인해 “회원”의 쇼핑몰 운영이 불가능해진 경우<br><br>" +

        "<strong>제 12 조 (“회원”에 대한 통지)</strong><br>" +
        "① “회사”는 “회원”에게 통지할 필요가 있는 경우, 본 “약관”에 별도로 명시되지 않은 이상, “회원”이 제공한 전자 우편주소, 휴대전화 번호, 주소 또는 서비스 로그인 시 나타나는 동의창 등 “회원”이 제공한 연락처를 통해 통지합니다.<br> " +
        "② “회원” 전체에 대한 통지는 서비스 웹사이트 내 공지사항에 7일 이상 게시함으로써 이루어집니다. “회원”의 서비스 이용에 중대한 영향을 미치는 중요 사항의 경우에는, 위에서 언급한 통지 방법 중 최소 두 가지 이상을 사용하여 통지합니다.<br> " +
        "③ “회원”은 “회사”에 자신의 최신 연락처 정보(전자 우편, 휴대전화 번호, 주소 등)를 제공하고, 이를 최신 상태로 유지할 의무가 있습니다. 또한, “회원”은 “회사”의 통지를 주기적으로 확인해야 합니다.<br> " +
        "④ “회원”이 자신의 정보 유지 의무를 소홀히 함으로써 발생하는 모든 불이익에 대해 “회사”는 책임지지 않습니다.<br><br>" +

        "<strong>제 13 조 (“회사”의 의무)</strong><br>" +
        "① “회사”는 모든 관련 법률 및 본 “약관”의 규정을 준수하며, 지속적이고 안정적인 서비스 제공을 위해 최선을 다합니다.<br> " +
        "② “회사”는 “회원”의 개인정보 및 신용정보를 보호하기 위해 적절한 보안 시스템을 유지하고, 개인정보 처리 방침을 공개하며 이를 엄격히 준수합니다.<br> " +
        "③ “회원”으로부터 제기된 의견이나 불만 사항이 정당한 경우, “회사”는 이를 적절히 처리하고 처리 과정 및 결과를 “회원”에게 통지합니다.<br> " +
        "④ “회사”는 “회원”의 동의 없이 “회원”의 비공개 정보(매출정보, 주문정보 등)를 제3자에게 공개하거나 제공하지 않습니다.<br> " +
        "⑤ “회사”는 거짓으로 표시된 전화번호 사용으로 인한 피해 예방을 위해 관련 법률 및 정부 지침에 따라 필요한 조치를 취합니다.<br> " +
        "⑥ “회사”는 발신번호의 변작을 방지하고, 정확한 발신번호 사용을 위해 번호 인증을 통한 발신번호 사전등록 서비스를 제공합니다.<br> " +
        "⑦ “회사”는 “회원” 가입 시 본인 인증 절차를 운영하여 타인 명의 도용 및 부정 가입을 방지합니다.<br> " +
        "⑧ “회사”는 “회원”이 발신번호 사전등록 및 본인 인증 절차를 완료한 후에 서비스를 제공합니다.<br> " +
        "⑨ “회사”는 “회원”의 서비스 이용과 관련하여 공개된 리뷰 정보, 상품 정보 등을 “회원”의 이익 증진을 위해 활용할 수 있습니다. “회원”은 이러한 정보 활용에 대해 거부할 권리가 있으며, 거부 의사를 표시한 경우 “회사”는 해당 정보를 활용하지 않습니다.<br><br>" +

        "<strong>제 14 조 (“회원” 의무)</strong><br>" +
        "① “회원”은 관련 법령, 본 “약관”, 서비스 운영 정책, 이용 안내 및 “회사”가 공지하거나 통지한 사항을 준수해야 하며, “회사”의 업무에 방해되는 행위를 해서는 안 됩니다. 이에는 불법 정보를 담고 있거나 법령을 위반하는 내용으로 “회원” 웹사이트를 구성하는 행위 등이 포함됩니다.<br> " +
        "② “회원”은 “회원”가입 시 “회사”가 제공하는 인증 방법을 통해 본인 인증을 완료한 후 서비스를 이용해야 합니다.<br> " +
        "③ “회원”은 자신의 “회원”계정을 서비스 이용 목적에만 사용해야 합니다.<br> " +
        "④ “회원”은 “회사”의 사전 승낙 없이 다음과 같은 행위를 해서는 안 됩니다<br>" +
        "1. 서비스에 제공되지 않는 방식으로 서비스에 접속하는 행위<br>" +
        "2. 노출 횟수 및 클릭 횟수를 부정하게 조작하는 행위<br>" +
        "3. “회사”의 서버 및 기타 설비에 부담을 주는 행위<br>" +
        "4. 서비스의 정상적인 운영을 방해하는 기타 행위<br> " +
        "⑤ “회원”은 서비스 이용 권한을 타인에게 대여하거나 양도할 수 없으며, 타인으로부터 서비스 이용 권한을 대여 받거나 양수할 수도 없습니다.<br> " +
        "⑥ “회원”은 “회사”의 동의 없이 자신의 계정을 이용하여 타인의 웹사이트, 상품, 서비스 또는 정보를 광고 목적으로 사용할 수 없습니다.<br> " +
        "⑦ “회원”은 자신의 정보에 타인의 정보나 허위 정보를 기재할 수 없습니다.<br> " +
        "⑧ “회원”은 서비스 이용 과정에서 취득한 모든 정보를 제3자에게 제공, 공개 또는 누설할 수 없습니다.<br> " +
        "⑨ “회사”가 서비스 제공 확인 또는 본 조 위반 여부를 확인하기 위해 정보 제공을 요청하는 경우, “회원”은 이에 성실히 협조해야 합니다.<br> " +
        "⑩ “회원”이 “약관” 또는 관련 법령을 위반하여 제3자가 “회사”에 문제를 제기하는 경우, “회원”은 문제 해결을 위해 적극 협조하고, 이로 인해 “회사”에 손해가 발생한 경우 손해를 배상해야 합니다.<br> " +
        "⑪ “회원”은 발신번호 변작 방지를 위해 번호 인증을 통한 사전 등록 후 서비스를 이용해야 합니다.<br><br>" +

        "<strong>제 15 조 (서비스의 이용 제한)</strong><br>" +
        "서비스 제공이 불가능하거나 제한될 수 있는 사유는 다음과 같습니다.<br> " +
        "천재지변, 시스템 장애 및 점검, 서비스 양도 및 폐지 등으로 인해 서비스 제공이 일시적으로 불가능한 경우.<br> " +
        "“회원”이 아래 사유에 해당하는 경우 서비스 이용이 제한될 수 있습니다.<br> " +
        "① 관리자 정보를 허위로 기재한 경우.<br> " +
        "② 본 “약관” 및 관련된 별도의 서비스 이용 규정을 위반한 경우.<br> " +
        "③ 타인의 명예를 현저하게 훼손한 경우.<br> " +
        "④ 서비스 비용의 결제가 고정된 결제일로부터 3일 이상 실패한 경우.<br> " +
        "⑤ “회사”의 영업을 방해하거나 동일한 업무를 수행하는 경우.<br> " +
        "⑥ 만 14세 미만의 미성년자가 서비스를 사용하는 경우.<br> " +
        "⑦ “회원”의 쇼핑몰 운영에 필수적인 호스팅 서비스 변경으로 서비스 제공이 불가능한 경우.<br> " +
        "⑧ “회원”의 쇼핑몰 도메인 변경 또는 “호스팅사” 아이디 변경으로 인한 서비스 제공 불가능한 경우.<br> " +
        "⑨ 미래창조과학부 (거짓으로 표시된 전화번호로 인한 이용자 피해 예방 등에 관한) 고시 제 4 조, 제 5 조, 제 7조에 따라 발신번호 미등록 또는 거짓 등록으로 인한 법적 조치에 따른 경우.<br> " +
        "⑩ 천재지변, 시스템 점검 및 장애, 서비스 폐지 등의 경우.<br>" +
        "서비스 제공이 불가능한 경우, “회사”는 “회원”에게 가능한 한 사전에 공지합니다. 천재지변을 제외하고 서비스 제공이 불가능할 때는 다음과 같은 조치를 취합니다<br>" +
        "1. 가능한 경우, 사전에 “회원”에게 공지합니다.<br>" +
        "2. 서비스 중단으로 “회원”에게 손해가 발생한 경우, 손해를 확인한 후 최고 1년 무상 서비스 또는 할인을 제공할 수 있습니다.<br>" +
        "3. “회사”가 서비스의 일부 또는 전부를 폐지하려는 경우, 폐지 예정일 최소 30일 전에 “회원”에게 이메일, 홈페이지 등을 통해 공지합니다.<br><br>" +

        "<strong>제 16 조 (서비스 해지)</strong><br>" +
        "① “회원”이 서비스 이용을 해지하고자 할 경우, 해지를 원하는 날짜 최소 15일 전에 “회사”에 유선, 이메일(E-mail), 또는 관리자 페이지를 통해 해지 의사를 명확하게 통보해야 합니다.<br> " +
        "② “회사”는 “회원”이 업무 방해 행위를 하거나 합리적이지 않은 업무 요구 등 “약관”을 위반하는 경우, 서비스 이용 계약을 즉시 해지할 수 있습니다.<br> " +
        "③ “회원”이 1년 이상 서비스에 접속하지 않는 등, 서비스를 사용하지 않는 것으로 판단될 경우, “회사”는 해당 “회원”의 서비스 이용을 해지할 수 있습니다.<br><br>" +

        "<strong>제 17 조 (“회원” 탈퇴)</strong><br>" +
        "① “회원”은 언제든지 고객센터를 통해 “회원” 탈퇴를 요청할 수 있으며, “회사”는 관련 법령에 따라 요청을 즉시 처리합니다.<br> " +
        "② “회원” 탈퇴가 완료되면, “회사”는 관련 법령 및 개인정보처리방침에 따라 “회원” 정보를 제외한 모든 정보를 즉각 삭제합니다.<br> " +
        "③ “회원” 탈퇴 후, 서비스 운영 정책에 따라 탈퇴한 계정 정보를 사용한 재가입이 제한될 수 있습니다.<br><br>" +

        "<strong>제 18 조 (손해배상 등)</strong><br>" +
        "① “회사”의 고의 또는 과실로 인해 “회원”에게 손해가 발생한 경우, 관련 법령에 따라 “회원”에게 손해를 배상합니다.<br> " +
        "② “회원”이 “약관” 또는 관련 법령을 위반하여 “회사”에 손해를 입힌 경우, 관련 법령에 따라 “회사”에 손해를 배상해야 합니다.<br><br>" +

        "<strong>제 19 조 (책임제한)</strong><br>" +
        "① “회사”는 천재지변, 디도스(DDoS) 공격, IDC 장애, 기간통신사업자의 회선 장애, 광고 매체의 장애 등 불가항력적 사유로 인하여 서비스를 제공할 수 없는 경우, 해당 상황에 대한 책임을 부담하지 않습니다.<br> " +
        "② “회사”는 무료로 제공하는 서비스에 대하여는 어떠한 책임도 지지 않습니다.<br> " +
        "③ “회원”의 귀책사유로 인해 발생한 서비스 이용 장애에 대해서 “회사”는 책임을 지지 않습니다.<br> " +
        "④ “회사”는 “회원”이 서비스를 통해 접근하게 되는 웹사이트, 상품, 서비스 또는 그 제공자의 신뢰도, 정확성, 적법성 등에 대하여 책임을 지지 않습니다.<br> " +
        "⑤ “회사”는 “회원” 상호간 또는 “회원”과 제3자 간에 서비스와 관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며, 해당 분쟁으로 인한 손해에 대해 “회사”에 귀책사유가 없는 한 배상 책임이 없습니다.<br><br>" +

        "<strong>제 20 조 (이용자 불만 및 문의형태별 답변 및 처리절차 및 기간)</strong><br>" +
        "① “회사”는 이용자의 불만 및 문의 사항을 최대한 즉시 답변을 하며, 이메일 및 서면으로 접수된 사항은 영업일 기준 1일 이내에 답변을 시작합니다.<br> " +
        "② 처리 기간은 사안의 복잡도에 따라 달라질 수 있으나, 원칙적으로 다음과 같이 진행됩니다<br>" +
        "가.“회사” 귀책: 고객 사과 및 품질 개선, 최대 7일 이내 처리.<br>" +
        "나.“회원” 귀책: 고객 설명, 최대 7일 이내 처리.<br><br>" +

        "<strong>제 21 조 (서비스 제공과 관련한 권리의 귀속)</strong><br>" +
        "① “회사”가 제공하는 서비스 및 서비스를 통해 제공되는 모든 기술, 정보, 노하우, 영상 등에 대한 권리(특허권, 저작권, 상표권, 영업비밀권, 지적재산권 및 기타 독점적 권리 포함)는 “회사”에 귀속됩니다.<br> " +
        "② “회사”는 서비스 이용 편의성 증진을 위해 해당 권리를 전 세계적으로 사용, 저장, 복제, 수정, 이차적 저작물 제작, 전달, 공개, 실연, 게시 및 배포할 수 있는 권리를 보유합니다. 이는 “회원”이 서비스 이용을 위해 제출한 정보를 포함하며, “회사”는 이를 상업적 목적으로 활용할 수 있습니다.<br><br>" +

        "<strong>제 22 조 (권리의 양도금지)</strong><br>" +
        "①양 당사자는 “회사”의 서면 동의 없이 본 “약관”에 따른 권리와 의무를 제3자에게 양도, 증여하거나 담보로 제공할 수 없습니다.<br><br>" +

        "<strong>제 23 조 (“약관”의 해석)</strong><br>" +
        "① “회사”는 본 “약관” 외에도 시스템 운영 정책 등 추가적인 정책을 둘 수 있습니다.<br> " +
        "② “약관”에서 명시하지 않은 사항이나 해석에 대해서는 광고 운영 정책, 이용 안내, 관련 법령을 기준으로 합니다.<br><br>" +

        "<strong>제 24 조 (준거법 및 재판관할)</strong><br>" +
        "① “회사”와 “회원” 간에 발생한 분쟁에 대하여는 대한민국 법을 준거법으로 합니다.<br> " +
        "② “회사”와 “회원” 간에 발생한 분쟁에 대한 소송은 민사소송법에 따른 관할 법원에 제기합니다.<br></p>" +
        "</div>"
};

